<template>
  <div class="bg-black">
   <div class="flex bg-gray-200">
      <div class="flex-1 bg-black">
       <router-link to="/"><img src="@/assets/images/logo.png" alt="" class="logo" /></router-link>
      </div>
      <div class="underline ... ">
        <h1 class="pages-heading1 text-white">Portfolio</h1>
      </div>
      <div class="side-bar">
        <div id="mySidenav" class="sidenav">
        <a href="javascript:void(0)" class="closebtn" v-on:click="closeNav()"
        >&times;</a>
          <a><router-link to="/">HOME</router-link></a>
          <a><router-link to="/about-us">ABOUT US</router-link></a>
           <a>
<div class="dropdown">
<input type="checkbox" id="my-dropdown">
<label for="my-dropdown" data-toggle="dropdown"><router-link to="/services">SERVICES</router-link></label>
<ul>
<li><a><router-link to="/marketing-solutions">MARKETING SOLUTIONS</router-link></a></li>
<li><a><router-link to="/web-development">WEB DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/mobile-development">APP DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/uiux">UIUX DESIGN</router-link></a></li>
</ul>
</div>
</a>
          <a><router-link to="/portfolio">PORTFOLIO</router-link></a>
          <a><router-link to="/contact">CONTACT US</router-link></a>
        </div>
        <img src="@/assets/images/navbar.svg" alt="" v-on:click="openNav()" class="navbar" />
      </div>
    </div>
    <div class="container-fluid ">
      <!-- <div class="underline ... color-orange">
        <h2 class="heading-pages ">Our Services</h2>
      </div>
      <p class="para-service">
        WE DELIVER VALUE and NOT FEATURES…
      </p>
      <p class="para-service">
        From the beginning, our vision for our work is clear. We want our work should provide value to our customer.
        It should solve their problem and help them win in the business world.
      </p>
      <p class="para-service">
        We believe that when you get obsessed about small things, big things happen.
      </p>
      <p class="para-service">
        This is why, whether we are creating apps or running your digital campaigns
        we are concerned about every little thing that contributes to big results
      </p>
      <p class="para-service">
      </p> -->
      <div class="grid grid-cols-2 ">
        <div>
        </div>
        <div>
        </div>
      </div>
    </div>
    <div class="container-fluid" style="margin-bottom:40px">
      <div class="box-content box-servicess">
        <div class="grid grid-cols-2 ">
          <div>
            <img
              class="services-img11"
              src="@/assets/images/mockups only (website)/alllines.png"
              alt=""
            />
          </div>
          <div>
            <h2 class="services-heading1"><router-link to="">Alllines Associates Inc</router-link></h2>
            <p class="services-para11">
              A beautiful illustration-based website for an insurance agency.
               Alllines Associated Inc. is a USA-based insurance agency, they wanted a simple and user-friendly
               interface for their website. Our designer created simple user navigation that will increase engagement
                and will capture the leads with the CTAs placed throughout the website.
            </p>
          </div>
        </div>
      </div>
      <div class="box-content box-services22">
        <div class="grid grid-cols-2 ">
          <div class="ccc">
            <h2 class="services-heading22"><router-link to="/web-development">Coolberg-IOS & ANDROID</router-link></h2>
            <p class="service-para4">
              When Coolberg, one of the top 50 startup in India approached us to
create an app that will let them manage their distributor and will
provide all the analytical insights that are based on complex functions,
it was a challenge to understand every process of their business.
This app was based on the third party sales software Coolberg was
using .
            </p>
          </div>
          <div>
            <img
              src="@/assets/images/mockups only (App)/coolberg.png"
              alt=""
              class="services-img22"
            />
          </div>
        </div>
      </div>
      <div class="box-content box-servicess">
        <div class="grid grid-cols-2 ">
          <div>
            <img
              class="services-img11"
              src="@/assets/images/Logo and branidng png images/cafe-starboy-shadow-logo_new (1) (1) (1).png"
              alt=""
            />
          </div>
          <div>
            <h2 class="services-heading1"><router-link to="/marketing-solutions">Cafe Starboy</router-link></h2>
            <p class="services-para11">
              Cafestarboy is a cafe located in Pune.
When the client approached us for branding,his requirement was to stand out from theother cafes located in the same area. Wecreated this vibrant logo based on the belowinsights.
            </p>
          </div>
        </div>
      </div>
      <div class="box-content box-services22">
        <div class="grid grid-cols-2 ">
          <div class="ccc">
            <h2 class="services-heading22"><router-link to="/web-development">G6 Industries</router-link></h2>
            <p class="service-para4">
              Understanding the client’s vision and reflecting it through the digital medium.
G6 Industry is a global company with many ventures and they are operating in many industries. When the client asked us to create a business website then he had a clear vision in his mind. The challenge was to have transparent communication with the client to have a clear understanding of his goals and what he exactly wants in the website.
            </p>
          </div>
          <div>
            <img
              src="@/assets/images/mockups only (website)/g6 industries (1).png"
              alt=""
              class="services-img22"
            />
          </div>
        </div>
      </div>
      <div class="box-content box-servicess">
        <div class="grid grid-cols-2 ">
          <div>
            <img
              class="services-img11"
              src="@/assets/images/mockups only (App)/tingler.png"
              alt=""
            />
          </div>
          <div>
            <h2 class="services-heading1"><router-link to="/marketing-solutions">Tingler-ANDROID</router-link></h2>
            <p class="services-para11">
              A fun social media app that lets you challeneg your friends to
complete a task for a reward.
The challenge with the tingler was to create a visually stunning UI-UX
that let's user challenge their friends and family.
Our designers created an amazing UI that lets user interact with every
feature of the app in most easiest way. From then the development
was a piece of cake.
            </p>
          </div>
        </div>
      </div>
      <div class="box-content box-services22">
        <div class="grid grid-cols-2 ">
          <div class="ccc">
            <h2 class="services-heading22"><router-link to="/web-development">KOSI</router-link></h2>
            <p class="service-para4">
             KOSI is an agriculture company based inMumbai and they manufacture organicproduce like grains, jaggery, spices, etc.
The client had asked us for a concept-based logo which will look good onpackaging.
            </p>
          </div>
          <div>
            <img
              src="@/assets/images/Logo and branidng png images/er.png"
              alt=""
              class="services-img22  mmm"
            />
          </div>
        </div>
      </div>
      <div class="box-content box-servicess">
        <div class="grid grid-cols-2 ">
          <div>
            <img
              class="services-img11"
              src="@/assets/images/mockups only (website)/prudence.png"
              alt=""
            />
          </div>
          <div>
            <h2 class="services-heading1"><router-link to="/marketing-solutions">Prudence infrastructure</router-link></h2>
            <p class="services-para11">
              An architecture website that feels like a piece of art.
Prudence infrastructure is a creative architectural agency based in Pune. When the client decided to create a website, he wanted visitors to feel like they are surrounded by beautiful art pieces of architecture. He also wanted to portray his years of work through the website.
            </p>
          </div>
        </div>
      </div>
      <div class="box-content box-services22">
        <div class="grid grid-cols-2 ">
          <div class="ccc">
            <h2 class="services-heading22"><router-link to="/web-development">Surfchem-ANDROID</router-link></h2>
            <p class="service-para4">
              Surfchem is an educational app that lets students access the
information of medical drugs. With more than 1000 drugs and its vital
information, Surfchem is a go to app for a medical students.
We created an app along with a data input system that let client
update, edit, add and delete the information of the drugs.
            </p>
          </div>
          <div>
            <img
              src="@/assets/images/mockups only (App)/surfchem 3 phone.png"
              alt=""
              class="services-img22"
            />
          </div>
        </div>
      </div>
      <div class="box-content box-servicess">
        <div class="grid grid-cols-2 ">
          <div>
            <img
              class="nnn"
              src="@/assets/images/Logo and branidng png images/logo01.png"
              alt=""
            />
          </div>
          <div>
            <h2 class="services-heading1"><router-link to="/marketing-solutions">RCPL Pvt Ltd</router-link></h2>
            <p class="services-para11">
              RCPL Cranes is a Pune-based crane
manufacturer. The Client wanted the
complete branding of the company including
visiting cards, logo, brochures and website.
            </p>
          </div>
        </div>
      </div>
      <div class="box-content box-services22">
        <div class="grid grid-cols-2 ">
          <div class="ccc">
            <h2 class="services-heading22"><router-link to="/web-development">U2 Digital</router-link></h2>
            <p class="service-para4">
              A fun website that matches the vibe of the client’s personality.
U2 digital is a digital agency based in Mumbai. The client wanted a website that will capture the leads and a mesmerizing content copy for the website. When we met the client, we realized the client is fun, casual but takes her work seriously and we wanted this vibe to reflect in her website.
            </p>
          </div>
          <div>
            <img
              src="@/assets/images/mockups only (website)/u2digital.png"
              alt=""
              class="services-img22"
            />
          </div>
        </div>
      </div>
      <div class="box-content box-servicess">
        <div class="grid grid-cols-2 ">
          <div>
            <img
              class="services-img11"
              src="@/assets/images/mockups only (App)/nookdays.png"
              alt=""
            />
          </div>
          <div>
            <h2 class="services-heading1"><router-link to="/marketing-solutions">Nookdays</router-link></h2>
            <p class="services-para11">A great way to cherish all your life’s adventures.
Nookdays is a social media platform that lets you create an event with your family and friends where you can share the memories of that particular event with each other. A memory sharing and recording platform for friends and families where you can see life from others' perspectives and stay connected through common memories.
</p>
          </div>
        </div>
      </div>
      <div class="box-content box-services22">
        <div class="grid grid-cols-2 ">
          <div class="ccc">
            <h2 class="services-heading22"><router-link to="/web-development">Chaai Centre</router-link></h2>
            <p class="service-para4">
             A complete redesigning of a brand identity for our Mumbai-based client who wanted an attractive logo that will resonate friendly, warm, and cool vibe.
            </p>
          </div>
          <div>
            <img
              src="@/assets/images/Logo and branidng png images/Artboard 20@2x.png"
              alt=""
              class="services-img22"
            />
          </div>
        </div>
      </div>
      <div class="box-content box-servicess">
        <div class="grid grid-cols-2 ">
          <div>
            <img
              class="services-img11"
              src="@/assets/images/mockups only (website)/g6 electric.png"
              alt=""
            />
          </div>
          <div>
            <h2 class="services-heading1"><router-link to="/marketing-solutions">G6 Electric Vehicle</router-link></h2>
            <p class="services-para11">
              Branding through a business website.
G6 electrical vehicle is a UK-based company. They wanted a business website for an awareness and branding purpose for their new target location (INDIA) where they want to expand the EV vehicles. The business objective was awareness and that is why we went the classic way of having easy navigation that will let website visitors access the information in the most convenient way and in minimum time.
            </p>
          </div>
        </div>
      </div>
      <div class="box-content box-services22">
        <div class="grid grid-cols-2 ">
          <div class="ccc">
            <h2 class="services-heading22"><router-link to="/web-development">Store-x</router-link></h2>
            <p class="service-para4">Store-x is an android application that manages the chemical distributionprocess.
The most difficult challenge with store-x was to manage theuncertainties of technology and keep it working 24*7 as entire businessprocess was dependent on this app.
We managed the every aspect of a app development process from codeto cloud and delivered what we promised to our client.</p>
          </div>
          <div>
            <img
              src="@/assets/images/mockups only (App)/third broker x.png"
              alt=""
              class="services-img22"
            />
          </div>
        </div>
      </div>
      <div class="box-content box-servicess">
        <div class="grid grid-cols-2 ">
          <div>
            <img
              class="services-img11"
              src="@/assets/images/Logo and branidng png images/look-logo-1.png"
              alt=""
            />
          </div>
          <div>
            <h2 class="services-heading1"><router-link to="/marketing-solutions">Looks Spa</router-link></h2>
            <p class="services-para11">A brand identity for a salon in Mumbai. The main goal was to attract the attention of a target customer (Women) and evoke the feeling of warmth and care.</p>
          </div>
        </div>
      </div>
      <div class="box-content box-services22">
        <div class="grid grid-cols-2 ">
          <div class="ccc">
            <h2 class="services-heading22"><router-link to="/web-development">Ace Agent Financial </router-link></h2>
            <p class="service-para4">
              Brand awareness through website.
AAF wanted an informative website that will let insurance agents be aware of the benefits of working with them.  The client wanted a focus on the imagery and content of the website. Our content writer wrote beautiful content copy and our web designer created an interface by the information.
            </p>
          </div>
          <div>
            <img
              src="@/assets/images/mockups only (website)/ace agent.png"
              alt=""
              class="services-img22"
            />
          </div>
        </div>
      </div>
      <div class="box-content box-servicess bg-darkgrey">
        <div class="grid grid-cols-2 ">
          <div>
            <img
              class="services-img11"
              src="@/assets/images/Logo and branidng png images/Panchatatva.png"
              alt=""
            />
          </div>
          <div>
            <h2 class="services-heading1"><router-link to="/mobile-development">Panchatatva</router-link></h2>
            <p class="services-para11">A brand identity for an organic food brand.
This logo was made by keeping companies’ vision and mission in mind, which is a 100% organic and pure food product.
The challenge was to create a brand identity that will reflect the same value.
</p>
          </div>
        </div>
      </div>
    </div>
      <Footer></Footer>
  </div>
</template>

<script>
import Footer from './partials/Footer.vue'
export default {
  components: {
    Footer
  },
  methods: {
    openNav () {
      document.getElementById('mySidenav').style.width = '250px'
    },
    closeNav () {
      document.getElementById('mySidenav').style.width = '0'
    }
  }
}
</script>
<style scoped>
/*
dropdown  navbar code started
*/
@font-face {
font-family: "FontAwesome";
font-weight: normal;
font-style : normal;
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0") format("embedded-opentype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0") format("woff2"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0") format("woff"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0") format("truetype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular") format("svg");
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown > input[type="checkbox"] {
  position: absolute;
  left: -100vw;
}

.dropdown > label,
.dropdown > a[role="button"] {
  display: inline-block;
  /* padding: 6px 15px; */
  color: #333;
  line-height: 1.5em;
  text-decoration: none;
  /* border: 1px solid #8c8c8c; */
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.dropdown > label:hover,
.dropdown > a[role="button"]:hover,
.dropdown > a[role="button"]:focus {
  border-color: #333;
}

.dropdown > label:after,
.dropdown > a[role="button"]:after {
  content: "\f0d7";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: 6px;
}

.dropdown > ul {
  position: absolute;
  z-index: 999;
  display: block;
  left: -100vw;
  top: calc(1.5em + 14px);
  /* border: 1px solid #8c8c8c; */
  background: rgb(29, 29, 29);
  padding: 6px 15px;
  margin: 0;
  list-style: none;
  width: 220px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  -moz-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  box-shadow: 0 3px 8px rgba(0,0,0,.15);
}

.dropdown > ul a {
  display: block;
  padding: 6px 15px;
  text-decoration: none;
  color: rgb(95, 94, 94);
}

.dropdown > ul a:hover,
.dropdown > ul a:focus {
  background: rgb(29, 29, 29);
  color: #dd6605;
}

.dropdown > input[type="checkbox"]:checked ~ ul,
.dropdown > ul:target {
  left: 0;
}

.dropdown > [type="checkbox"]:checked + label:after,
.dropdown > ul:target ~ a:after {
  content: "\f0d8";
}

.dropdown a.close {
  display: none;
}

.dropdown > ul:target ~ a.close {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-indent: -100vw;
  z-index: 1000;
}
/*
 Demo purposes only
*/
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  padding: 30px;
  color: #333;
}

.dropdown + h2 {
  margin-top: 60px;
}
/*dropdown  navbar code finished
*/
</style>
