<template>
  <div class="main_container">
    <div class="flex bg-gray-200">
      <div class="flex-1 bg-black">
        <router-link to="/"><img src="@/assets/images/logo.png" alt="" class="logo" /></router-link>
      </div>
      <div class="side-bar">
        <div id="mySidenav" class="sidenav">
         <a href="javascript:void(0)" class="closebtn" v-on:click="closeNav()"
        >&times;</a>
          <a><router-link to="/">HOME</router-link></a>
          <a><router-link to="/about-us">ABOUT US</router-link></a>
          <a>
<div class="dropdown">
<input type="checkbox" id="my-dropdown">
<label for="my-dropdown" data-toggle="dropdown"><router-link to="/services">SERVICES</router-link></label>
<ul>
<li><a><router-link to="/marketing-solutions">MARKETING SOLUTIONS</router-link></a></li>
<li><a><router-link to="/web-development">WEB DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/mobile-development">APP DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/uiux">UIUX DESIGN</router-link></a></li>
</ul>
</div>
</a>
          <a><router-link to="/portfolio">PORTFOLIO</router-link></a>
          <a><router-link to="/contact">CONTACT US</router-link></a>
        </div>
        <img src="@/assets/images/navbar.svg" alt="" v-on:click="openNav()" class="navbar" />
      </div>
    </div>
<div class="carousel">
    <div class="carousel-inner">
        <input class="carousel-open" type="radio" id="carousel-1" name="carousel" aria-hidden="true" hidden="" checked="checked">
        <div class="carousel-item">
            <img src="@/assets/images/card.gif">
        </div>
        <input class="carousel-open" type="radio" id="carousel-2" name="carousel" aria-hidden="true" hidden="">
        <div class="carousel-item">
            <img src="@/assets/images/s2.png">
        </div>
        <input class="carousel-open" type="radio" id="carousel-3" name="carousel" aria-hidden="true" hidden="">
        <div class="carousel-item">
            <img src="@/assets/images/s3.png">
        </div>
        <label for="carousel-3" class="carousel-control prev control-1">‹</label>
        <label for="carousel-2" class="carousel-control next control-1">›</label>
        <label for="carousel-1" class="carousel-control prev control-2">‹</label>
        <label for="carousel-3" class="carousel-control next control-2">›</label>
        <label for="carousel-2" class="carousel-control prev control-3">‹</label>
        <label for="carousel-1" class="carousel-control next control-3">›</label>
        <ol class="carousel-indicators">
            <li>
                <label for="carousel-1" class="carousel-bullet">•</label>
            </li>
            <li>
                <label for="carousel-2" class="carousel-bullet">•</label>
            </li>
            <li>
                <label for="carousel-3" class="carousel-bullet">•</label>
            </li>
        </ol>
    </div>
</div>
    <!-- <div class="container-fluid bg-black">
      <div class="{styles.main_container}">
        <div >
          <h2 class="heading-pages heading-why ">Why Choose Rdiobutton?</h2>
        </div>
 </div>
       <div class="card_slider">
         <div>
          <h2 class="heading-pagesw heading-why ">Why Choose Rdiobutton</h2>
        </div>
        <div class="card">
  <input type="radio" name="select" id="slide_1" checked>
  <input type="radio" name="select" id="slide_2">
  <input type="radio" name="select" id="slide_3">
  <input type="checkbox" id="slideImg">
  <div class="slider">
    <label for="slide_1" class="slide slide_1"></label>
    <label for="slide_2" class="slide slide_2"></label>
    <label for="slide_3" class="slide slide_3"></label>
  </div>
  <div class="inner_part">
    <div class="content content_1">
       <p >01</p>
      <div class="title">Customer-centric approach</div>
      <div class="text">
        Empathy is the most important ingredient in our
organization. We don't create features, but we serve as
problem solvers to our customers. Before creating any
digital tool for the customer, we keep ourselves in the
customer's shoes and aim to provide the best solution.
      </div>
    </div>
    <label for="slideImg" class="img">
      <img class="img_1 "  src="@/assets/images/phatake2_00000.gif">
    </label>
  </div>
  <div class="inner_part">
    <div class="content content_2">
    <p >02</p>
      <div class="title">Innovation</div>
      <div class="text">
       The only way to lead is just not to create but to innovate
the idea too. We believe in innovation and stay updated
with the latest technologies. We try to find ways to
provide better solutions to our customers.
      </div>
    </div>
    <label for="slideImg" class="img">
      <img class="img_2" src="@/assets/images/girl-and-boy-holding-bulb-2_00000.gif">
    </label>
  </div>
  <div class="inner_part">
    <div class="content content_3">
       <p >03</p>
      <div class="title">Design-focused</div>
      <div class="text">Designs are the most ignored part of product
development and yet it is the most effective part which
can make a product work for you. We are a design centric agency and provide ingenious excellent services
in advertising, branding and UI designs
      </div>
    </div>
    <label for="slideImg" class="img">
      <img class="img_3" src="@/assets/images/sculpting2_00000.gif">
    </label>
  </div>
</div>
  </div>
    </div> -->
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from './partials/Footer.vue'
export default {
  components: {
    Footer
  },
  methods: {
    openNav () {
      document.getElementById('mySidenav').style.width = '250px'
    },
    closeNav () {
      document.getElementById('mySidenav').style.width = '0'
    }
  }
}
</script>
<style scoped>
/* .sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
} */
/*
dropdown  navbar code started
*/
@font-face {
font-family: "FontAwesome";
font-weight: normal;
font-style : normal;
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0") format("embedded-opentype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0") format("woff2"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0") format("woff"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0") format("truetype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular") format("svg");
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown > input[type="checkbox"] {
  position: absolute;
  left: -100vw;
}

.dropdown > label,
.dropdown > a[role="button"] {
  display: inline-block;
  /* padding: 6px 15px; */
  color: #333;
  line-height: 1.5em;
  text-decoration: none;
  /* border: 1px solid #8c8c8c; */
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.dropdown > label:hover,
.dropdown > a[role="button"]:hover,
.dropdown > a[role="button"]:focus {
  border-color: #333;
}

.dropdown > label:after,
.dropdown > a[role="button"]:after {
  content: "\f0d7";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: 6px;
}

.dropdown > ul {
  position: absolute;
  z-index: 999;
  display: block;
  left: -100vw;
  top: calc(1.5em + 14px);
  /* border: 1px solid #8c8c8c; */
  background: rgb(29, 29, 29);
  padding: 6px 15px;
  margin: 0;
  list-style: none;
  width: 220px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  -moz-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  box-shadow: 0 3px 8px rgba(0,0,0,.15);
}

.dropdown > ul a {
  display: block;
  padding: 6px 15px;
  text-decoration: none;
  color: rgb(95, 94, 94);
}

.dropdown > ul a:hover,
.dropdown > ul a:focus {
  background: rgb(29, 29, 29);
  color: #dd6605;
}

.dropdown > input[type="checkbox"]:checked ~ ul,
.dropdown > ul:target {
  left: 0;
}

.dropdown > [type="checkbox"]:checked + label:after,
.dropdown > ul:target ~ a:after {
  content: "\f0d8";
}

.dropdown a.close {
  display: none;
}

.dropdown > ul:target ~ a.close {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-indent: -100vw;
  z-index: 1000;
}
/*
 Demo purposes only
*/
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  padding: 30px;
  color: #333;
}

.dropdown + h2 {
  margin-top: 60px;
}
/*dropdown  navbar code finished
*/
.card_slider{
  display: flex;
  align-items: center;
  justify-content: center;
}
.card {
  position: absolute;
  height: 550px;
  width: 100%;
  max-width: 950px;
  margin: auto;
  background-color: black;
  border-radius: 5px;
  box-shadow: 10px 0 50px rgba(0, 0, 0, 0.5);
}
.card .inner_part {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50 0 0 0px;
  height: 440px;

  position: absolute;
}
#slideImg:checked ~ .inner_part {
  /*padding: 0;
  transition: .1s ease-in;*/
}
.inner_part .img {
  height:400px;
  width:640px;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 0px;
  margin-top: 4px;
  box-shadow: 0 10px 50px rgba(0,0,0,0.2);
}
#slideImg:checked ~ .inner_part .img {
 /*height: 350px;
 width: 850px;
 z-index: 99;
 transition: .3s .2s ease-in;*/
}
.img img {
  height: 100%;
  /*width: 100%;*/
  cursor: pointer;
  opacity: 0;
  transition: .6s;
}
#slide_1:checked ~ .inner_part .img_1,
#slide_2:checked ~ .inner_part .img_2,
#slide_3:checked ~ .inner_part .img_3 {
  opacity: 1;
  transition-delay: .2s;
  margin-left: 60px;
}
.content {
  padding: 0 20px 0 5px;
  width: 530px;
  /* margin-left: 20px; */
  opacity: 0;
  transition: .6s;
}
#slideImg:checked ~ .inner_part .content {
  /*display: none;*/
}
#slide_1:checked ~ .inner_part .content_1,
#slide_2:checked ~ .inner_part .content_2,
#slide_3:checked ~ .inner_part .content_3 {
  opacity: 1;
  margin-left: -90px;
  z-index: 100;
  transition-delay: .3s;
}
.content .title {
  font-size: 30px;
  font-weight: 700;
  color: #dd6605;
  margin: 0 0 20px 0;
}
.content .text {
  font-size: 16px;
  color: gray;
  margin: 0 auto 30px auto;
  line-height: 1.5em;
  /* text-align: justify; */
}
.content button {
  padding: 15px 20px;
  border: none;
  font-size: 16px;
  color: #fff0e6;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
  background: #000000;
  float: right;
}
.content button:hover {
  background: #cecece;
  color: #000000;
}
.content p{
  color: white;
  font-size: 30px;
    -webkit-text-decoration-line: line-through; /* Safari */
   text-decoration-line: line-through;
   padding-bottom:20px;
   color:grey;

}
.slider {
  position: absolute;
  bottom: 100px;
  left: 0%;
  transform: translateX(-50%);
  z-index: 1;
}
#slideImg:checked ~ .slider {
  /*display: none;*/
}
.slider .slide {
  position: relative;
  height: 10px;
  width: 50px;
  background: #dd6605;
  border-radius: 5px;
  display: inline-flex;
  margin: 0 3px;
  cursor: pointer;
}
.slider .slide:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: -100%;
  background: #fff;;
  border-radius: 10px;
  transform: scaleX(0);
  transition: transform .6s;
  transform-origin: left;
}
#slide_1:checked ~ .slider .slide_1:before,
#slide_2:checked ~ .slider .slide_2:before,
#slide_3:checked ~ .slider .slide_3:before {
  transform: scaleX(1);
  width: 100%;
}
.card input {
  display: none;
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  padding: 5px;
  background: #fff;
  border-radius: 3px;
  width: 60%;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  top: 5px;
  right: 5px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #dd6605;
}
.popup .content1 {
  background: #000000;
  max-height: 90%;
  width: 100%;
  overflow: auto;
}
html, body {
    margin: 0px;
    padding: 0px;
    background: url("http://digital.bnint.com/filelib/s9/photos/white_wood_4500x3000_lo_res.jpg");
}

.carousel {
    position: relative;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.64);
    margin-top: 26px;
}

.carousel-inner {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.carousel-open:checked + .carousel-item {
    position: static;
    opacity: 100;
}

.carousel-item {
    position: absolute;
    opacity: 0;
    -webkit-transition: opacity 0.6s ease-out;
    transition: opacity 0.6s ease-out;
}

.carousel-item img {
    display: block;
    height: auto;
    max-width: 100%;
}

.carousel-control {
    background: rgba(0, 0, 0, 0.28);
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: none;
    font-size: 40px;
    height: 40px;
    line-height: 35px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    cursor: pointer;
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    text-align: center;
    width: 40px;
    z-index: 10;
}

.carousel-control.prev {
    left: 2%;
}

.carousel-control.next {
    right: 2%;
}

.carousel-control:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #dd6605;
}

#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3 {
    display: block;
}

.carousel-indicators {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 2%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
}

.carousel-indicators li {
    display: inline-block;
    margin: 0 5px;
}

.carousel-bullet {
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 35px;
}

.carousel-bullet:hover {
    color: #dd6605;
}

#carousel-1:checked ~ .control-1 ~ .carousel-indicators li:nth-child(1) .carousel-bullet,
#carousel-2:checked ~ .control-2 ~ .carousel-indicators li:nth-child(2) .carousel-bullet,
#carousel-3:checked ~ .control-3 ~ .carousel-indicators li:nth-child(3) .carousel-bullet {
    color: #dd6605;
}

#title {
    width: 100%;
    position: absolute;
    padding: 0px;
    margin: 0px auto;
    text-align: center;
    font-size: 27px;
    color: rgba(255, 255, 255, 1);
    font-family: 'Open Sans', sans-serif;
    z-index: 9999;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.33), -1px 0px 2px rgba(255, 255, 255, 0);
}
@media screen and (max-width: 700px) {
.card_slider[data-v-91c82b1e] {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 480px;
}

    #slide_1:checked ~ .inner_part .content_1[data-v-91c82b1e], #slide_2:checked ~ .inner_part .content_2[data-v-91c82b1e], #slide_3:checked ~ .inner_part .content_3[data-v-91c82b1e] {
    opacity: 1;
    margin-left: 10px;
    z-index: 100;
    transition-delay: .3s;
}

.content[data-v-91c82b1e] {
    padding: 0 20px 0 5px;
    width: 175px;
    margin-left: 20px;
    opacity: 0;
    transition: .6s;
}

.content .title[data-v-91c82b1e] {
    font-size: 15px;
    font-weight: 700;
    color: #dd6605;
    margin: 0 0 20px0;
}

.content p[data-v-91c82b1e] {
    color: white;
    font-size: 20px;
    -webkit-text-decoration-line: line-through;
    text-decoration-line: line-through;
    padding-bottom: 10px;
    color: grey;
}

.content .text[data-v-91c82b1e] {
    font-size: 11px;
    color: gray;
    margin: 0 auto 30px auto;
    line-height: 1.5em;
    /* text-align: justify; */
}

.card .inner_part[data-v-91c82b1e] {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50 0 0 0px;
    height: 444px;
    position: absolute;
}

.inner_part .img[data-v-91c82b1e] {
    height: 220px;
    width: 280px;
    overflow: hidden;
    flex-shrink: 0;
    border-radius: 0px;
    margin-top: 4px;
    margin-left: -70px;
    box-shadow: 0 10px 50px rgb(0 0 0 / 20%);
}

.img img[data-v-91c82b1e] {
    height: 160px;
    /* width: 100%; */
    cursor: pointer;
    opacity: 0;
    transition: .6s;
}

.slider[data-v-91c82b1e] {
    position: absolute;
    bottom: 160px;
    left: 0%;
    transform: translateX(-50%);
    z-index: 1111;
    margin-left: 94px;
}
}

@media screen and (max-width: 1024px) {
.card_slider[data-v-91c82b1e] {
    margin-left: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 630px;
}
.img img[data-v-91c82b1e] {
    /* width: 100%; */
    height: 325px;
    FONT-VARIANT: JIS04;
    cursor: pointer;
    opacity: 0;
    transition: .6s;
}
}
</style>
