<template>
  <div class="main_container">
    <div class="flex bg-gray-200">
      <div class="flex-1 bg-black">
        <router-link to="/"><img src="@/assets/images/logo.png" alt="" class="logo" /></router-link>
      </div>
      <div class="side-bar">
        <div id="mySidenav" class="sidenav">
         <a href="javascript:void(0)" class="closebtn" v-on:click="closeNav()"
        >&times;</a>
          <a><router-link to="/">HOME</router-link></a>
          <a><router-link to="/about-us">ABOUT US</router-link></a>
          <a>
<div class="dropdown">
<input type="checkbox" id="my-dropdown">
<label for="my-dropdown" data-toggle="dropdown"><router-link to="/services">SERVICES</router-link></label>
<ul>
<li><a><router-link to="/marketing-solutions">MARKETING SOLUTIONS</router-link></a></li>
<li><a><router-link to="/web-development">WEB DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/mobile-development">APP DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/uiux">UIUX DESIGN</router-link></a></li>
</ul>
</div>
</a>
          <a><router-link to="/portfolio">PORTFOLIO</router-link></a>
          <a><router-link to="/contact">CONTACT US</router-link></a>
        </div>
        <img src="@/assets/images/navbar.svg" alt="" v-on:click="openNav()" class="navbar" />
      </div>
    </div>
    <div
      class="grid grid-cols-2 justify-center content-center items-center flex-home"
    >
      <div class=" text-gray-700  bg-black padding-f1 ">
        <h1 class="text-5xl text-white padding-head-f1">
          Not your regular,unreliable, boring tech company.....
        </h1>
        <p class="para-home">
          We help brands and businesses solve their business pain with
           meaningful web, mobile and digital solutions.
        </p>
        <div class="flex bg-gray-200">
          <div class="flex-1 bg-black margin-button-home">
            <!-- <button
              class="bb2 bb1 button-talk button-services bg-#dd6605   text-white font-semibold hover:bg-transparent hover:text-white py-2 px-6 border border-#dd6605 rectangle"
            >
              View More
            </button>

            <button
              class="bb2 button-talk button-services bg-#dd6605   text-white font-semibold hover:bg-transparent hover:text-white py-2 px-6 border border-#dd6605 rectangle"
            >
              <router-link to="/contact">Talk to Us</router-link>
            </button> -->

             <button
            class="bd button-talk button-services-a bg-#dd6605   text-white font-semibold hover:bg-transparent hover:text-white py-2 px-6 border border-#dd6605 rectangle"
         >
            <a href="#meh">View More</a>
          </button>
          <button
            class=" button-talk button-services-a bg-#dd6605   text-white font-semibold hover:bg-transparent hover:text-white py-2 px-6 border border-#dd6605 rectangle"
          >
          <a href="#popup1">Talk to Us</a>
          </button>
          </div>
        </div>
      </div>
      <div class=" text-gray-700  bg-black ">
        <img src="@/assets/images/ezgif.com-gif-maker (2).gif" alt="" />
      </div>
    </div>
<div id="popup1" class="overlay">
<div class="popup" style="background-color:black">
<h2>Rdiobutton</h2>
<a class="close" href="#">&times;</a>
<div class="content1">
<div class="w-2/4 flex-none p-2">
            <div class="text-grey bg-black">
              <form class="form-width">
                <div class="mb-4">
                  <label
                    class="block text-white text-sm font-bold mb-2"
                    for="username"
                  >
                    Name*
                  </label>

                  <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="username"
                    type="text"
                  />
                </div>
                <div class="mb-4">
                  <label
                    class="block text-white text-sm font-bold mb-2"
                    for="username"
                  >
                    Email*
                  </label>
                  <input
                    class="shadow appearance-none border rounded w-full
                  py-2 px-3 text-gray-700 leading-tight focus:outline-none
                  focus:shadow-outline"
                    id="username"
                    type="text"
                  />
                </div>
                <div class="mb-4"><br>
                  <label
                    class="block text-white text-sm font-bold mb-2"
                    for="username"
                  >
                    Services*
                  </label>
                  <select class="shadow appearance-none border rounded w-full
                  py-2 px-3 text-gray-700 leading-tight focus:outline-none
                  focus:shadow-outline" id="username"
                    type="text">
                    <option>Web Development</option>
                    <option>App Development</option>
                    <option>UX & UI Designing</option>
                    <option>Graphic Designing</option>
                  </select>
                </div>
                <div class="mb-4"><br>
                  <label
                    class="block text-white text-sm font-bold mb-2"
                    for="username"
                  >
                    Message
                  </label>
                  <input
                    class="shadow appearance-none border rounded w-full
                  py-2 px-3 text-gray-700 leading-tight focus:outline-none
                  focus:shadow-outline"
                    id="username"
                    type="text"
                  />
                </div>
              </form>
              <button
                class="button-services-a bg-orange-500 hover:bg-transparent text-white font-bold py-2 px-4 border border-orange -700 rounded"
              >
                Send
              </button>
            </div>
          </div>
           <div class="w-2/4 flex-none p-2">
            <div class="text-gray-700 bg-black">
              <img src="@/assets/images/girl-and-boy-calling-2_00000.gif" alt="" class="img-contact" />
            </div>
          </div>
</div>
</div>
</div>
    <div class="{styles.main_container} padding-sec2 brac" id="meh">
      <div class="">
        <h2 class="heading-pages ">Services</h2>
      </div>
      <div class="flex">
        <div class="w-1/3 services-border">
          <div class="bg-black">
            <div
              class="max-w-sm rounded overflow-hidden shadow-lg bg-black services-flex group border-orange hover:bg-transparent hover:border-orange "
            >
              <img
                class="services-img"
                src="@/assets/images/Marketing solution.svg"
                alt=""
              />
              <div class="px-6 py-4 bg-black ">
                <div class="services-text ">MARKETING SOLUTIONS</div>
                <!-- <p class="para">
                  Struggling to grow your monthly revenue?<br>
                  Is your business being in the race of survival where you are just fighting to keep your business alive?<br>
                  If yes then you need our branding and marketing solutions to solve your problem before its too late.
                </p> -->
                 <p class="para">
                If you think that your traditional business practices can do wonders to your
                business then you might enter into committing a fatal mistake for your business.
                </p>
                Button
                <button
                  class="m1 button-servicesx bg-orange-500 hover:bg-transparent text-white font-bold py-2 px-4 border border-orange -700 rounded"
                ><router-link to="/marketing-solutions">
                  Read More</router-link>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-1/3 services-border">
          <div class="bg-black">
            <div
              class="max-w-sm rounded overflow-hidden shadow-lg bg-black services-flex"
            >
              <img
                class="services-img"
                src="@/assets/images/Web development.svg"
                alt=""
              />
              <div class="px-6 py-4 bg-black">
                <div class="services-text">WEB<br> DEVELOPMENT</div>
                <!-- <p class="para">
                  Don’t let the digital face of your business ugly. We create visually stunning websites which are
                  crafted by creative designers and developed by experience developers to deliver high performing
                  web solutions.
                </p> -->
                 <p class="para" >
                Web development is continuously evolving at very rapid rate.
                 It is very important to keep up with the latest languages and
                  trends to provide amazing results to the customers.
                </p>
                Button
                <button
                  class="m1 button-servicesy bg-orange-500 hover:bg-transparent text-white font-bold py-2 px-4 border border-orange -700 rounded"
                ><router-link to="/web-development">
                  Read More</router-link>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-1/3 services-border">
          <div class="bg-black">
            <div
              class="max-w-sm rounded overflow-hidden shadow-lg bg-black services-flex"
            >
              <img
                class="services-img"
                src="@/assets/images/App development.svg"
                alt=""
              />
              <div class="px-6 py-4 bg-black">
                <div class="services-text">APP<br> DEVELOPMENT</div>
                <!-- <p class="para">
                  We build solid, sturdy and high performing mobile applications for your business. From the ideation
                  to implementation, our mobile app developers get obsessed with every step of application
                  development to bring your application ideas to life. Our team can turn the app of any complexity to
                  live solution.
                </p> -->
                 <p class="para" >
                From evaluation of your user's needs to the performance of your app we have
                 end to end solutions for your mobile development projects. We know the kind
                 of expertise needed to develope mobile apps.
                </p>
                Button
                <button
                  class="m1 button-servicesz bg-orange-500 hover:bg-transparent text-white font-bold py-2 px-4 border border-orange -700 rounded"
                ><router-link to="/mobile-development">
                  Read More</router-link>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-1/3 services-border">
          <div class="bg-black">
            <div
              class="max-w-sm rounded overflow-hidden shadow-lg bg-black services-flex"
            >
              <img
                class="services-img"
                src="@/assets/images/UIUX - graphic.svg"
                alt=""
              />
              <div class="px-6 py-4 bg-black">
                <div class="services-text">UI UX & GRAPHIC DESIGN</div>
                <!-- <p class="para">
                  What really a great design does? Great designs sell the attention, attract your customer and grow
                  your business.A great business website, a logo or well-designed web application is
                  not just about visuals but it is all about the growth of your business.
                </p> -->
                <p class="para">
                A well-designed product steals the attention. attract the customers,
                engages the user and retain your clients. We believe that design is
                not how thing looks but how thing works.
                </p>
                Button
                <button
                  class="m1 button-servicesv bg-orange-500 hover:bg-transparent text-white font-bold py-2 px-4 border border-orange -700 rounded"
                ><router-link to="/uiux">
                  Read More</router-link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid bg-black">
      <div class="">
        <h2 class="heading-pages work-head ">Work</h2>

       <h2 class="heading-pages work-head" style="margin-top:-55px;color:#dd6605">Let our work start the conversation.</h2>
      </div>
      <!-- <div class="row">
        <div class="col-6">
        <img style="height:200px;" src="@/assets/images/work/1a.png" alt="" />
        </div>
        <div class="col-6">
        <img style="height:200px;" src="@/assets/images/work/2a.png" alt="" />
        </div>
        <div class="col-md-6">
        <img  src="@/assets/images/work/3a.png" alt="" />
        </div>
        <div class="col-md-6">
        <img  src="@/assets/images/work/4a.png" alt="" />
        </div>
      </div> -->
      <div class="grid grid-cols-2">
        <div>
           <img  src="@/assets/images/work/allines.png" alt="" />
        </div>
        <div>
         <img  src="@/assets/images/work/ace agent (1).png" alt="" />
        </div>
      </div>
        <div class="grid grid-cols-2">
        <div>
           <img  src="@/assets/images/work/cb.png" alt="" />
        </div>
        <div>
           <img  src="@/assets/images/work/tingler.png" alt="" />
        </div>
      </div>
      <div class="grid grid-cols-2">
        <div>
           <img  src="@/assets/images/work/g6 industries (1) (1).png" alt="" />
        </div>
        <div>
         <img  src="@/assets/images/work/prudencee.png" alt="" />
        </div>
      </div>
        <div class="grid grid-cols-2">
        <div>
           <img  src="@/assets/images/work/surfchem.png" alt="" />
        </div>
        <div>
           <img  src="@/assets/images/work/nookdays.png" alt="" />
        </div>
      </div>
      <div class="grid grid-cols-2">
        <div>
           <img  src="@/assets/images/work/g6ev.png" alt="" />
        </div>
        <div>
         <img  src="@/assets/images/work/u2.png" alt="" />
        </div>
      </div>
      <!--<div class="{styles.main_container}">
        <div >
          <h2 class="heading-pages heading-why ">Why Choose Rdiobutton?</h2>
        </div>
 <div class="flex flex-no-wrap bg-black">
          <div class="w-2/4 flex-none p-2">
            <div class="text-gray-700 text-center bg-black">
              <p class="number line-through ...">01</p>
              <h1 class="heading-ce">Customer-centric</h1>
              <p class="para-services">
                Being customer centric is not about building the features as per their requirements,
                it is about going beyond that and understanding their pain and
                solving their problems with innovative solutions and helping them grow.
              </p>
            </div>
          </div>
          <div class="w-2/4 flex-none p-2">
            <div class="text-gray-700 bg-black img-customer">
              <img src="@/assets/images/Customer experience-1.png" alt="" class="img-customer" />
            </div>
          </div>
        </div>
 </div>-->
      <!-- <div class="card_slider">
         <div >
          <h2 class="heading-pagesw heading-why ">Why Choose Rdiobutton</h2>
        </div>
        <div class="card">
  <input type="radio" name="select" id="slide_1" checked>
  <input type="radio" name="select" id="slide_2">
  <input type="radio" name="select" id="slide_3">
  <input type="checkbox" id="slideImg">
  <div class="slider">
    <label for="slide_1" class="slide slide_1"></label>
    <label for="slide_2" class="slide slide_2"></label>
    <label for="slide_3" class="slide slide_3"></label>
  </div>
  <div class="inner_part">
    <div class="content content_1">
       <p >01</p>
      <div class="title">Customer-centric approach</div>
      <div class="text">
        Empathy is the most important ingredient in our
organization. We don't create features, but we serve as
problem solvers to our customers. Before creating any
digital tool for the customer, we keep ourselves in the
customer's shoes and aim to provide the best solution.
      </div>
    </div>
    <label for="slideImg" class="img">
      <img class="img_1 "  src="@/assets/images/phatake2_00000.gif">
    </label>
  </div>
  <div class="inner_part">
    <div class="content content_2">
    <p >02</p>
      <div class="title">Innovation</div>
      <div class="text">
       The only way to lead is just not to create but to innovate
the idea too. We believe in innovation and stay updated
with the latest technologies. We try to find ways to
provide better solutions to our customers.
      </div>
    </div>
    <label for="slideImg" class="img">
      <img class="img_2" src="@/assets/images/girl-and-boy-holding-bulb-2_00000.gif">
    </label>
  </div>
  <div class="inner_part">
    <div class="content content_3">
       <p >03</p>
      <div class="title">Design-focused</div>
      <div class="text">Designs are the most ignored part of product
development and yet it is the most effective part which
can make a product work for you. We are a design centric agency and provide ingenious excellent services
in advertising, branding and UI designs
      </div>
    </div>
    <label for="slideImg" class="img">
      <img class="img_3" src="@/assets/images/sculpting2_00000.gif">
    </label>
  </div>
</div>
  </div> --><div class="">
        <h2 class="heading-pages ">Why Choose Rdiobutton?</h2>
      </div>
  <div class="carousel">
    <div class="carousel-inner">
        <input class="carousel-open" type="radio" id="carousel-1" name="carousel" aria-hidden="true" hidden="" checked="checked">
        <div class="carousel-item">
            <img src="@/assets/images/fc.gif">
        </div>
        <input class="carousel-open" type="radio" id="carousel-2" name="carousel" aria-hidden="true" hidden="">
        <div class="carousel-item">
            <img src="@/assets/images/gb.gif">
        </div>
        <input class="carousel-open" type="radio" id="carousel-3" name="carousel" aria-hidden="true" hidden="">
        <div class="carousel-item">
            <img src="@/assets/images/card.gif">
        </div>
        <label for="carousel-3" class="carousel-control prev control-1">‹</label>
        <label for="carousel-2" class="carousel-control next control-1">›</label>
        <label for="carousel-1" class="carousel-control prev control-2">‹</label>
        <label for="carousel-3" class="carousel-control next control-2">›</label>
        <label for="carousel-2" class="carousel-control prev control-3">‹</label>
        <label for="carousel-1" class="carousel-control next control-3">›</label>
        <!-- <ol class="carousel-indicators">
            <li>
                <label for="carousel-1" class="carousel-bullet">•</label>
            </li>
            <li>
                <label for="carousel-2" class="carousel-bullet">•</label>
            </li>
            <li>
                <label for="carousel-3" class="carousel-bullet">•</label>
            </li>
        </ol> -->
    </div>
</div>
      <div class="{styles.main_container}">
        <div >
          <h2 class="heading-pagesw ">Our Clients</h2>
        </div>
        <div class="flex bg-black topm">
          <div class="flex-1 text-gray-700 text-center bg-black px-4 py-2 m-2 bb">
            <img src="@/assets/images/cafe peter.png" class="client-img11"/>
          </div>
          <div class="flex-1 text-gray-700 text-center bg-black px-4 py-2 m-2 bb">
            <img src="@/assets/images/Group 548.png" class="client-img12" />
          </div>
          <div class="flex-1 text-gray-700 text-center bg-black px-4 py-2 m-2 bb">
            <img src="@/assets/images/Group 549.png" class="client-img13" />
          </div>
          <div class="flex-1 text-gray-700 text-center bg-black px-4 py-2 m-2 bb">
            <img src="@/assets/images/Group 550.png" class="client-img14" />
          </div>
        </div>
        <div class="flex bg-black topm">
          <div class="flex-1 text-gray-700 text-center bg-black px-4 py-2 m-2 bb">
            <img src="@/assets/images/g6 industries.png" class="client-img1" />
          </div>
          <div class="flex-1 text-gray-700 text-center bg-black px-4 py-2 m-2 bb">
            <img src="@/assets/images/g6 lpgo png.png" class="client-img1" />
          </div>
          <div class="flex-1 text-gray-700 text-center bg-black px-4 py-2 m-2 bb">
            <img src="@/assets/images/prudence (1).png" class="client-img1a" />
          </div>
          <div class="flex-1 text-gray-700 text-center bg-black px-4 py-2 m-2 bb">
            <img src="@/assets/images/coolberg logo wo bg.png" class="client-img1a" />
          </div>
        </div>
      </div>
      <div class="{styles.main_container}">
        <div>
          <h2 class="heading-clients ">Contact Us</h2>
        </div>
        <div class="flex flex-no-wrap bg-black">
          <div class="w-2/4 flex-none p-2">
            <div class="text-gray-700 bg-black">
              <img src="@/assets/images/girl-and-boy-calling-2_00000.gif" alt="" class="img-contact" />
            </div>
          </div>
          <div class="w-2/4 flex-none p-2">
            <div class="text-grey bg-black">
              <form class="form-width" ref="form" @submit.prevent="sendEmail">
                <div class="mb-4">
                  <label
                    class="block text-white text-sm font-bold mb-2"
                    for="username"
                  >
                    Name*
                  </label>

                  <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="username"
                    type="text" name="name"
                  />
                </div>
                <div class="mb-4">
                  <label
                    class="block text-white text-sm font-bold mb-2"
                    for="username"
                  >
                    Email*
                  </label>
                  <input
                    class="shadow appearance-none border rounded w-full
                  py-2 px-3 text-gray-700 leading-tight focus:outline-none
                  focus:shadow-outline"
                    id="username"
                    type="text"  name="email"
                  />
                </div>
                <!-- <div class="inline-block relative w-64">
                  <label
                    class="block text-white text-sm font-bold mb-2"
                    for="username"
                  >
                    Services*
                  </label>
                   <select
                    class="ff1 block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline form-service"
                   >
                   <select class="shadow appearance-none border rounded w-full
                  py-2 px-3 text-gray-700 leading-tight focus:outline-none
                  focus:shadow-outline form-service">
                    <option>Web Development</option>
                    <option>App Development</option>
                    <option>UX & UI Designing</option>
                    <option>Graphic Designing</option>
                  </select>
                </div> -->
                <div class="mb-4"><br>
                  <label
                    class="block text-white text-sm font-bold mb-2"
                    for="username"
                  >
                    Services*
                  </label>
                  <select class="shadow appearance-none border rounded w-full
                  py-2 px-3 text-gray-700 leading-tight focus:outline-none
                  focus:shadow-outline" id="username"
                    type="text" name="service">
                    <option>Web Development</option>
                    <option>App Development</option>
                    <option>UX & UI Designing</option>
                    <option>Graphic Designing</option>
                  </select>
                </div>
                <div class="mb-4"><br>
                  <label
                    class="block text-white text-sm font-bold mb-2"
                    for="username"
                  >
                    Message
                  </label>
                  <input
                    class="shadow appearance-none border rounded w-full
                  py-2 px-3 text-gray-700 leading-tight focus:outline-none
                  focus:shadow-outline"
                    id="username"
                    type="text" name="message"
                  />
                </div>
                <input class="button-services-a bg-orange-500 hover:bg-transparent text-white font-bold py-2 px-4 border border-orange -700 rounded" type="submit" name="" value="Send">
              </form>
              <!-- <button
                class="button-services-a bg-orange-500 hover:bg-transparent text-white font-bold py-2 px-4 border border-orange -700 rounded"
              >
                Send
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from './partials/Footer.vue'
import emailjs from 'emailjs-com'
export default {
  components: {
    Footer
  },
  methods: {
    openNav () {
      document.getElementById('mySidenav').style.width = '250px'
    },
    closeNav () {
      document.getElementById('mySidenav').style.width = '0'
    },
    sendEmail () {
      emailjs.sendForm('service_qelpcx4', 'template_rdsoxwm', this.$refs.form, 'user_2mydx2XI0sCHC3LcNG2CC')
        .then((result) => {
          console.log('SUCCESS!', result.text)
        }, (error) => {
          console.log('FAILED...', error.text)
        })
    }
  }
}
</script>
<style scoped>
/* .sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
} */
/*
dropdown  navbar code started
*/
@font-face {
font-family: "FontAwesome";
font-weight: normal;
font-style : normal;
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0") format("embedded-opentype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0") format("woff2"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0") format("woff"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0") format("truetype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular") format("svg");
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown > input[type="checkbox"] {
  position: absolute;
  left: -100vw;
}

.dropdown > label,
.dropdown > a[role="button"] {
  display: inline-block;
  /* padding: 6px 15px; */
  color: #333;
  line-height: 1.5em;
  text-decoration: none;
  /* border: 1px solid #8c8c8c; */
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.dropdown > label:hover,
.dropdown > a[role="button"]:hover,
.dropdown > a[role="button"]:focus {
  border-color: #333;
}

.dropdown > label:after,
.dropdown > a[role="button"]:after {
  content: "\f0d7";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: 6px;
}

.dropdown > ul {
  position: absolute;
  z-index: 999;
  display: block;
  left: -100vw;
  top: calc(1.5em + 14px);
  /* border: 1px solid #8c8c8c; */
  background: rgb(29, 29, 29);
  padding: 6px 15px;
  margin: 0;
  list-style: none;
  width: 220px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  -moz-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  box-shadow: 0 3px 8px rgba(0,0,0,.15);
}

.dropdown > ul a {
  display: block;
  padding: 6px 15px;
  text-decoration: none;
  color: rgb(95, 94, 94);
}

.dropdown > ul a:hover,
.dropdown > ul a:focus {
  background: rgb(29, 29, 29);
  color: #dd6605;
}

.dropdown > input[type="checkbox"]:checked ~ ul,
.dropdown > ul:target {
  left: 0;
}

.dropdown > [type="checkbox"]:checked + label:after,
.dropdown > ul:target ~ a:after {
  content: "\f0d8";
}

.dropdown a.close {
  display: none;
}

.dropdown > ul:target ~ a.close {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-indent: -100vw;
  z-index: 1000;
}
/*
 Demo purposes only
*/
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  padding: 30px;
  color: #333;
}

.dropdown + h2 {
  margin-top: 60px;
}
/*dropdown  navbar code finished
*/
html, body {
    margin: 0px;
    padding: 0px;
    background: url("http://digital.bnint.com/filelib/s9/photos/white_wood_4500x3000_lo_res.jpg");
}

.carousel {
    position: relative;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.64);
    margin-top: 26px;
}

.carousel-inner {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.carousel-open:checked + .carousel-item {
    position: static;
    opacity: 100;
}

.carousel-item {
    position: absolute;
    opacity: 0;
    -webkit-transition: opacity 0.6s ease-out;
    transition: opacity 0.6s ease-out;
}

.carousel-item img {
    display: block;
    height: auto;
    max-width: 100%;
}

.carousel-control {
    background: transparent;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: none;
    font-size: 40px;
    height: 40px;
    line-height: 35px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    cursor: pointer;
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    text-align: center;
    width: 40px;
    z-index: 10;
}

.carousel-control.prev {
    left: 7%;
    margin-top:15%;
}

.carousel-control.next {
    right: 85%;
    margin-top:15%;
}

.carousel-control:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #dd6605;
}

#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3 {
    display: block;
}

.carousel-indicators {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 2%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
}

.carousel-indicators li {
    display: inline-block;
    margin: 0 5px;
}

.carousel-bullet {
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 35px;
}

.carousel-bullet:hover {
    color: #dd6605;
}

#carousel-1:checked ~ .control-1 ~ .carousel-indicators li:nth-child(1) .carousel-bullet,
#carousel-2:checked ~ .control-2 ~ .carousel-indicators li:nth-child(2) .carousel-bullet,
#carousel-3:checked ~ .control-3 ~ .carousel-indicators li:nth-child(3) .carousel-bullet {
    color: #dd6605;
}

#title {
    width: 100%;
    position: absolute;
    padding: 0px;
    margin: 0px auto;
    text-align: center;
    font-size: 27px;
    color: rgba(255, 255, 255, 1);
    font-family: 'Open Sans', sans-serif;
    z-index: 9999;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.33), -1px 0px 2px rgba(255, 255, 255, 0);
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  padding: 5px;
  background: #fff;
  border-radius: 3px;
  width: 60%;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  top: 5px;
  right: 5px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #dd6605;
}
.popup .content1 {
  background: #000000;
  max-height: 90%;
  width: 100%;
  overflow: auto;
}
@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 700px) {
  .carousel-control {
    background: transparent;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: none;
    font-size: 30px;
    height: 30px;
    line-height: 30px;
    position: absolute;
    top: 52%;
    -webkit-transform: translate(0, -50%);
    cursor: pointer;
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    text-align: center;
    width: 30px;
    z-index: 10;
}

.carousel-control.prev {
    left: 7%;
    margin-top:16%;
}

.carousel-control.next {
    right: 75%;
    margin-top:16%;
}
}
</style>
