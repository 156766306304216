<template>
  <div class="bg-black">
     <div class="flex bg-gray-200">
      <div class="flex-1 bg-black">
        <router-link to="/"><img src="@/assets/images/logo.png" alt="" class="logo" /></router-link>
      </div>
      <div class="underline ... ">
        <h1 class="pages-heading-a text-white">Marketing Solutions</h1>
      </div>
      <div class="side-bar">
        <div id="mySidenav" class="sidenav">
        <a href="javascript:void(0)" class="closebtn" v-on:click="closeNav()"
        >&times;</a>
          <a><router-link to="/">HOME</router-link></a>
          <a><router-link to="/about-us">ABOUT US</router-link></a>
           <a>
<div class="dropdown">
<input type="checkbox" id="my-dropdown">
<label for="my-dropdown" data-toggle="dropdown"><router-link to="/services">SERVICES</router-link></label>
<ul>
<li><a><router-link to="/marketing-solutions">MARKETING SOLUTIONS</router-link></a></li>
<li><a><router-link to="/web-development">WEB DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/mobile-development">APP DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/uiux">UIUX DESIGN</router-link></a></li>
</ul>
</div>
</a>
          <a><router-link to="/portfolio">PORTFOLIO</router-link></a>
          <a><router-link to="/contact">CONTACT US</router-link></a>
        </div>
        <img src="@/assets/images/navbar.svg" alt="" v-on:click="openNav()" class="navbar" />
      </div>
    </div>
    <div class="container-fluid bg-black ">
      <div class="grid grid-cols-2">
        <div>
          <div class=" ">
            <h2 class="text-white about-head1">Marketing Is More Than Just Selling</h2>
          </div>
          <input type="checkbox" class="read-more-state" id="post-1" />
            <p class="read-more-wrap text-white about-para">
            Marketing is not just about paid campaigns or organic ranking. Marketing is about how you are
            projecting to your customer, how they are perceiving your brand and how your customers are
            getting attracted to your business. Marketing is not just selling your product or services, it is about
            setting your business objective and creating a significant mark on your prospect’s mind with great
            branding and marketing strategies so that they will keep coming back to work with you.
             <span class="read-more-target">
           Since the inception of Rdiobutton our mission is the same which is to create a significant impact to
                  the world with the technology. More than results we focus on the process as we believe if your work
                  process is disciplined and sturdy then results will follow.
                  It’s all about relieving the pain of customers with our technology and digital solutions. We are aware
                  of the fact that great customer experience can be achieved by providing value and not with building
                  features and that is what we aim to deliver to customers.</span> </p>
    <label for="post-1" class="read-more-trigger  button-services bg-orange-500 hover:bg-transparent text-white font-bold py-2 px-2 border border-orange -700 rounded"></label>
        </div>
        <div>
          <img src="@/assets/images/girl-broadcasting-2_000002.gif" alt="" class="marketing-img1" />
        </div>
      </div>
    </div>
    <div class="box-content box-servicesp">
      <div class="grid grid-cols-2">
        <div>
          <img src="@/assets/images/A1.png" alt="" class="marketing-num1" />
        </div>
        <div>
          <h2 class="marketing-des1">Search Media Marekting</h2>
          <p class="marketing-smm">
            With paid advertisement or search engine results pages (or SERPs), we plan to make you reach
             on top in your competition. We identify what keywords will suit your business and hedge on
              what customers are planning to use when searching for your product.
          </p>
          </div>
      </div>
    </div>
    <div class="box-content box-servicesp">
      <div class="grid grid-cols-2">
        <div>
          <img src="@/assets/images/A2.png" alt="" class="marketing-num1" />
        </div>
        <div>
          <h2 class="marketing-des1">Social Media Marekting</h2>
          <p class="marketing-smm">
           As the name suggests, we use social media platforms to promote your website or business through our strategic social media marketing skills. And we make sure that you connect with your target audience at the desired social landscape.
          </p>
        </div>
      </div>
    </div>
    <div class="box-content box-servicesp">
      <div class="grid grid-cols-2">
        <div>
          <img src="@/assets/images/A3.png" alt="" class="marketing-num1" />
        </div>
        <div>
          <h2 class="marketing-des1">Search Engine Optimization</h2>
          <p class="marketing-smm">
            Want to grab attention? SEO strategy is a key to gain successful ranking in the digital era. SEO services with the relevant keywordshelp you become more visible on search engines where your customers are trying to access your content.Rdiobutton will ensure that your brand, website never stops revving.
          </p>
        </div>
      </div>
    </div>
    <div class="box-content box-servicesp">
      <div class="grid grid-cols-2">
        <div>
          <img src="@/assets/images/A4.png" alt="" class="marketing-num1" />
        </div>
        <div>
          <h2 class="marketing-des1">Social Media Optimization</h2>
          <p class="marketing-smm">
            A pretty straightforward concept to increase the visibility and reach of your product in the market. Rdiobutton helps you to attain greater engagement through consistent process of creating poised content which can perform well.
          </p>
        </div>
      </div>
    </div>
    <div class="">
      <div>
        <h2 class="heading-pages ">Case Studies</h2>
      </div>
      <div class="flex padding-ms">
        <div class="w-1/3 services-border">
          <div class="bg-black">
            <div
              class="max-w-sm rounded overflow-hidden shadow-lg bg-black services-flex group border-orange hover:bg-transparent hover:border-orange "
            >
              <img class="img-ms" src="@/assets/images/g1.png" alt="" />
              <p class="marketing-para1">
                How we helped ACMI to reach out to its tech savvy customers.
              </p>
              <button
            class="ns button-talk button-services-a bg-#dd6605   text-white font-semibold hover:bg-transparent hover:text-white py-2 px-6 border border-#dd6605 rectangle"
         >
            <router-link to="/casestudy1">View More</router-link>
          </button>
            </div>
          </div>
        </div>
        <div class="w-1/3 services-border">
          <div class="bg-black">
            <div
              class="max-w-sm rounded overflow-hidden shadow-lg bg-black services-flex"
            >
              <img class="img-ms" src="@/assets/images/g2.png" alt="" />
              <p class="marketing-para1">
                How we used our digital marketing strategy to make people aware of their brand.
              </p>
              <button
            class="ns button-talk button-services-a bg-#dd6605   text-white font-semibold hover:bg-transparent hover:text-white py-2 px-6 border border-#dd6605 rectangle"
         >
            <router-link to="/casestudy2">View More</router-link>
          </button>
            </div>
          </div>
        </div>
        <div class="w-1/3 services-border">
          <div class="bg-black">
            <div
              class="max-w-sm rounded overflow-hidden shadow-lg bg-black services-flex"
            >
              <img class="img-ms" src="@/assets/images/g3.png" alt="" />
              <p class="marketing-para1">
                Lorem ipsum dolor sit consectetur adipiscing elit, sed do
                eiusmod tempor.
              </p>
              <button
            class="ns button-talk button-services-a bg-#dd6605   text-white font-semibold hover:bg-transparent hover:text-white py-2 px-6 border border-#dd6605 rectangle"
         >
            <router-link to="/casestudy1">View More</router-link>
          </button>
            </div>
          </div>
        </div>
         <div class="w-1/3 services-border">
          <div class="bg-black">
            <div
              class="max-w-sm rounded overflow-hidden shadow-lg bg-black services-flex"
            >
              <img class="img-ms" src="@/assets/images/g4.png" alt="" />
              <p class="marketing-para1">
                Lorem ipsum dolor sit consectetur adipiscing elit, sed do
                eiusmod tempor.
              </p>
              <button
            class="ns button-talk button-services-a bg-#dd6605   text-white font-semibold hover:bg-transparent hover:text-white py-2 px-6 border border-#dd6605 rectangle"
         >
            <router-link to="/casestudy1">View More</router-link>
          </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="">
      <div>
        <h2 class="heading-pages ">Marketing Solution Blogs</h2>
      </div>
      <div class="container-boxed bg-black ">
        <div class="grid grid-cols-3 divider-sec topm">
          <div class="blog-ui">
            <img src="@/assets/images/f1.png" alt="" class="ui-blog1 footer-31" />
            <p class=" md-para11 ">Why you should execute your</p>
            <p class=" md-para12 ">App idea if you trust it</p>
            <p class=" md-para13 ">View More</p>
          </div>
          <div class="blog-ui">
            <img src="@/assets/images/f2.png" alt="" class="ui-blog2 footer-41" />
            <p class=" md-para11 ">Why you should execute your</p>
            <p class=" md-para12 ">App idea if you trust it</p>
            <p class=" md-para13 ">View More</p>
          </div>
          <div class="blog-ui">
            <img src="@/assets/images/f3.png" alt="" class="ui-blog3 footer-51" />
            <p class=" md-para11 ">Why you should execute your</p>
            <p class=" md-para12 ">App idea if you trust it</p>
            <p class=" md-para13 ">View More</p>
          </div>
        </div>
      </div>
    </div> -->
    <div style="text-align:right">
    <button
            class="button-img1 bg-black hover:bg-transparent text-white font-bold py-2 px-4 rounded"
         >
            <router-link to="/web-development"><img src="@/assets/images/Group 885.png" class="foo sn"  /></router-link>
    </button>
    </div>
    <div class="container-fluid  marketing-footer">
      <h2 class="footer-marketing">Lets team up and have conversation!</h2>
      <h1 class="footer-hire">Hire Us!</h1>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from './partials/Footer.vue'
export default {
  components: {
    Footer
  },
  methods: {
    openNav () {
      document.getElementById('mySidenav').style.width = '250px'
    },
    closeNav () {
      document.getElementById('mySidenav').style.width = '0'
    }
  }
}
</script>
<style scoped>
/*
dropdown  navbar code started
*/
@font-face {
font-family: "FontAwesome";
font-weight: normal;
font-style : normal;
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0") format("embedded-opentype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0") format("woff2"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0") format("woff"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0") format("truetype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular") format("svg");
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown > input[type="checkbox"] {
  position: absolute;
  left: -100vw;
}

.dropdown > label,
.dropdown > a[role="button"] {
  display: inline-block;
  /* padding: 6px 15px; */
  color: #333;
  line-height: 1.5em;
  text-decoration: none;
  /* border: 1px solid #8c8c8c; */
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.dropdown > label:hover,
.dropdown > a[role="button"]:hover,
.dropdown > a[role="button"]:focus {
  border-color: #333;
}

.dropdown > label:after,
.dropdown > a[role="button"]:after {
  content: "\f0d7";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: 6px;
}

.dropdown > ul {
  position: absolute;
  z-index: 999;
  display: block;
  left: -100vw;
  top: calc(1.5em + 14px);
  /* border: 1px solid #8c8c8c; */
  background: rgb(29, 29, 29);
  padding: 6px 15px;
  margin: 0;
  list-style: none;
  width: 220px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  -moz-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  box-shadow: 0 3px 8px rgba(0,0,0,.15);
}

.dropdown > ul a {
  display: block;
  padding: 6px 15px;
  text-decoration: none;
  color: rgb(95, 94, 94);
}

.dropdown > ul a:hover,
.dropdown > ul a:focus {
  background: rgb(29, 29, 29);
  color: #dd6605;
}

.dropdown > input[type="checkbox"]:checked ~ ul,
.dropdown > ul:target {
  left: 0;
}

.dropdown > [type="checkbox"]:checked + label:after,
.dropdown > ul:target ~ a:after {
  content: "\f0d8";
}

.dropdown a.close {
  display: none;
}

.dropdown > ul:target ~ a.close {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-indent: -100vw;
  z-index: 1000;
}
/*
 Demo purposes only
*/
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  padding: 30px;
  color: #333;
}

.dropdown + h2 {
  margin-top: 60px;
}
/*dropdown  navbar code finished
*/
.read-more-state {
  display: none;
}

.read-more-target {
  opacity: 0;
  max-height: 0;
  font-size: 0;
  transition: .25s ease;
}

.read-more-state:checked ~ .read-more-wrap .read-more-target {
  opacity: 1;
  font-size: inherit;
  max-height: 999em;
}

.read-more-state ~ .read-more-trigger:before {
  content: 'Read more';
}

.read-more-state:checked ~ .read-more-trigger:before {
  content: 'Read less';
}

 .read-more-trigger {
  cursor: pointer;
  display: inline-block;
  margin-top:0 ;
  padding: 0 .8em;
  font-size: .9em;
  margin-left:13%;
  line-height: 3;
    width: 105px;
    text-align: center;
}

@media screen and (max-width: 700px) {
.read-more-trigger {
  cursor: pointer;
  display: inline-block;
  margin-top: 10px;
  padding: 0 .5em;
  font-size: .9em;
  line-height: 2;
  margin-left:9%;
}
}
</style>
