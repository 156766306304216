<template>
  <div class="bg-black">
   <div class="flex bg-gray-200">
      <div class="flex-1 bg-black">
       <router-link to="/"><img src="@/assets/images/logo.png" alt="" class="logo" /></router-link>
      </div>
      <div class="underline ... ">
        <h1 class="pages-heading1 text-white">Services</h1>
      </div>
      <div class="side-bar">
        <div id="mySidenav" class="sidenav">
        <a href="javascript:void(0)" class="closebtn" v-on:click="closeNav()"
        >&times;</a>
          <a><router-link to="/">HOME</router-link></a>
          <a><router-link to="/about-us">ABOUT US</router-link></a>
          <a>
<div class="dropdown">
<input type="checkbox" id="my-dropdown">
<label for="my-dropdown" data-toggle="dropdown"><router-link to="/services">SERVICES</router-link></label>
<ul>
<li><a><router-link to="/marketing-solutions">MARKETING SOLUTIONS</router-link></a></li>
<li><a><router-link to="/web-development">WEB DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/mobile-development">APP DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/uiux">UIUX DESIGN</router-link></a></li>
</ul>
</div>
</a>
          <a><router-link to="/portfolio">PORTFOLIO</router-link></a>
          <a><router-link to="/contact">CONTACT US</router-link></a>
        </div>
        <img src="@/assets/images/navbar.svg" alt="" v-on:click="openNav()" class="navbar" />
      </div>
    </div>
    <div class="container-fluid ">
      <!-- <div class="underline ... color-orange">
        <h2 class="heading-pages ">Our Services</h2>
      </div>
      <p class="para-service">
        WE DELIVER VALUE and NOT FEATURES…
      </p>
      <p class="para-service">
        From the beginning, our vision for our work is clear. We want our work should provide value to our customer.
        It should solve their problem and help them win in the business world.
      </p>
      <p class="para-service">
        We believe that when you get obsessed about small things, big things happen.
      </p>
      <p class="para-service">
        This is why, whether we are creating apps or running your digital campaigns
        we are concerned about every little thing that contributes to big results
      </p>
      <p class="para-service">
      </p> -->
      <div class="grid grid-cols-2 ">
        <div>
          <div>
            <h2 class="text-white about-head1">Our Services</h2>
          </div>
          <p class="text-white about-para">
           <span style="color:#dd6605;">WE DELIVER VALUE, NOT FEATURES…</span><br>
            From the beginning, our vision for our work is clear. We want our services to provide value to our customer, solve their business problem and help them win in business world. When you focus on your process, better solutions are created and this is why we thrive to make our work process solid to deliver incredible experiences to our clients.
          </p>
        </div>
        <div>
          <img
            src="@/assets/images/services2.png"
            alt=""
            class="marketing-img5 marketing-img118"
          />
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="box-content box-services">
        <div class="grid grid-cols-2 ">
          <div>
            <img
              class="services-img1"
              src="@/assets/images/Marketing solution.svg"
              alt=""
            />
          </div>
          <div>
            <h2 class="services-heading1"><router-link to="/marketing-solutions">Marketing Solutions</router-link></h2>
            <p class="services-para1">
              If you think that your traditional business practices can do wonders to your business then you might
              enter into committing a fatal mistake for your business. In this digital age its important to have
              strategic presence on internet and create significant impact on your customers mind and we don’t
              think there is any better way to do that except digital marketing.
            </p>
            <!-- <h3 class="service-view1"><router-link to="/marketing-solutions">view more</router-link></h3> -->
             <button
            class="service-view1 button-talk button-services-a bg-#dd6605   text-white font-semibold hover:bg-transparent hover:text-white py-2 px-6 border border-#dd6605 rectangle"
         >
            <router-link to="/marketing-solutions">View More</router-link>
          </button>
          </div>
        </div>
      </div>
      <div class="box-content box-services2">
        <div class="grid grid-cols-2 ">
          <div class="ccc">
            <h2 class="services-heading2"><router-link to="/web-development">Web Development</router-link></h2>
            <p class="service-para4">
              Web development is continuously evolving at very rapid rate. It is very important to keep up with
              the latest languages and trends to provide amazing results to the customers. Our full stack
              developers work with the talented team of UI-UX designers to deliver stunning websites and
              dynamic web apps which helps you achieve your branding and sales goals.
            </p>
            <!-- <h3 class="service-view2"><router-link to="/web-development">view more</router-link></h3> -->
            <button
            class="service-view2 button-talk button-services-a bg-#dd6605   text-white font-semibold hover:bg-transparent hover:text-white py-2 px-6 border border-#dd6605 rectangle"
         >
            <router-link to="/web-development">View More</router-link>
          </button>
          </div>
          <div>
            <img
              src="@/assets/images/Web development.svg"
              alt=""
              class="services-img2"
            />
          </div>
        </div>
      </div>
      <div class="box-content box-services bg-darkgrey">
        <div class="grid grid-cols-2 ">
          <div>
            <img
              class="services-img1"
              src="@/assets/images/App development.svg"
              alt=""
            />
          </div>
          <div>
            <h2 class="services-heading1"><router-link to="/mobile-development">App Development</router-link></h2>
            <p class="services-para1">
              Mobile application development is not just about Android and IOS expertise. From evaluation of
              your users’ needs to the performance of your app we have end to end solutions for your mobile
              development projects. We know the kind of expertise needed to develop exceptional mobile apps
              that people will use.
            </p>
            <!-- <h3 class="service-view3"><router-link to="/mobile-development">view more</router-link></h3> -->
            <button
            class="service-view3 button-talk button-services-a bg-#dd6605   text-white font-semibold hover:bg-transparent hover:text-white py-2 px-6 border border-#dd6605 rectangle"
         >
            <router-link to="/mobile-development">View More</router-link>
            </button>
          </div>
        </div>
      </div>
      <div class="box-content box-services4">
        <div class="grid grid-cols-2 ">
          <div class="ccc">
            <h2 class="services-heading4"><router-link to="/uiux">UI/UX & Graphic Design</router-link></h2>
            <p class="service-para4 service-para5">
              A well-designed product steals the attention, attract the customers, engages the user and retain
              your clients.  We believe that design is not how thing looks but how thing works. Whether it is a
              brand identity of your business or a UI-UX of mobile application, we align our design solution to your
              brand and business objectives to help you achieve your goal.
            </p>

            <!-- <h3 class="service-view4"><router-link to="/uiux">view more</router-link></h3> -->
            <button
            class="service-view4 button-talk button-services-a bg-#dd6605   text-white font-semibold hover:bg-transparent hover:text-white py-2 px-6 border border-#dd6605 rectangle"
         >
            <router-link to="/uiux">View More</router-link>
          </button>
          </div>
          <div>
            <img
              src="@/assets/images/UIUX - graphic.svg"
              alt=""
              class="services-img2"
            />
          </div>
        </div>
      </div>
    </div>
      <Footer></Footer>
  </div>
</template>

<script>
import Footer from './partials/Footer.vue'
export default {
  components: {
    Footer
  },
  methods: {
    openNav () {
      document.getElementById('mySidenav').style.width = '250px'
    },
    closeNav () {
      document.getElementById('mySidenav').style.width = '0'
    }
  }
}
</script>
<style scoped>
/*
dropdown  navbar code started
*/
@font-face {
font-family: "FontAwesome";
font-weight: normal;
font-style : normal;
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0") format("embedded-opentype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0") format("woff2"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0") format("woff"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0") format("truetype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular") format("svg");
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown > input[type="checkbox"] {
  position: absolute;
  left: -100vw;
}

.dropdown > label,
.dropdown > a[role="button"] {
  display: inline-block;
  /* padding: 6px 15px; */
  color: #333;
  line-height: 1.5em;
  text-decoration: none;
  /* border: 1px solid #8c8c8c; */
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.dropdown > label:hover,
.dropdown > a[role="button"]:hover,
.dropdown > a[role="button"]:focus {
  border-color: #333;
}

.dropdown > label:after,
.dropdown > a[role="button"]:after {
  content: "\f0d7";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: 6px;
}

.dropdown > ul {
  position: absolute;
  z-index: 999;
  display: block;
  left: -100vw;
  top: calc(1.5em + 14px);
  /* border: 1px solid #8c8c8c; */
  background: rgb(29, 29, 29);
  padding: 6px 15px;
  margin: 0;
  list-style: none;
  width: 220px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  -moz-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  box-shadow: 0 3px 8px rgba(0,0,0,.15);
}

.dropdown > ul a {
  display: block;
  padding: 6px 15px;
  text-decoration: none;
  color: rgb(95, 94, 94);
}

.dropdown > ul a:hover,
.dropdown > ul a:focus {
  background: rgb(29, 29, 29);
  color: #dd6605;
}

.dropdown > input[type="checkbox"]:checked ~ ul,
.dropdown > ul:target {
  left: 0;
}

.dropdown > [type="checkbox"]:checked + label:after,
.dropdown > ul:target ~ a:after {
  content: "\f0d8";
}

.dropdown a.close {
  display: none;
}

.dropdown > ul:target ~ a.close {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-indent: -100vw;
  z-index: 1000;
}
/*
 Demo purposes only
*/
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  padding: 30px;
  color: #333;
}

.dropdown + h2 {
  margin-top: 60px;
}
/*dropdown  navbar code finished
*/
</style>
