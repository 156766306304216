<template>
  <div class="bg-black">
    <div class="flex bg-gray-200">
      <div class="flex-1 bg-black">
        <router-link to="/"><img src="@/assets/images/logo.png" alt="" class="logo" /></router-link>
      </div>
      <div class="underline ... ">
        <h1 class="pages-heading-d text-white">UI/UX & Graphic Design</h1>
      </div>
      <div class="side-bar">
        <div id="mySidenav" class="sidenav">
        <a href="javascript:void(0)" class="closebtn" v-on:click="closeNav()"
        >&times;</a>
          <a><router-link to="/">HOME</router-link></a>
          <a><router-link to="/about-us">ABOUT US</router-link></a>
           <a>
<div class="dropdown">
<input type="checkbox" id="my-dropdown">
<label for="my-dropdown" data-toggle="dropdown"><router-link to="/services">SERVICES</router-link></label>
<ul>
<li><a><router-link to="/marketing-solutions">MARKETING SOLUTIONS</router-link></a></li>
<li><a><router-link to="/web-development">WEB DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/mobile-development">APP DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/uiux">UIUX DESIGN</router-link></a></li>
</ul>
</div>
</a>
          <a><router-link to="/portfolio">PORTFOLIO</router-link></a>
          <a><router-link to="/contact">CONTACT US</router-link></a>
        </div>
        <img src="@/assets/images/navbar.svg" alt="" v-on:click="openNav()" class="navbar" />
      </div>
    </div>
    <div class="container-fluid bg-black ">
      <div class="grid grid-cols-2 mbot">
        <div class="bbb">
          <div>
            <h2 class="text-white about-head1">UI/UX Graphic Design</h2>
          </div>
          <p class="text-white about-para">
            Design has a purpose, whether it is to grab the attention of your customers or to provide a better
            experience through a well-designed UX.  Our creative designers always keep you ahead with
            solution-oriented design and continuously find better ways to represent your brand or product with
            design thinking.
          </p>
        </div>
        <div>
          <img src="@/assets/images/girl drawing.gif" alt="" class="marketing-img4" />
        </div>
      </div>
    </div>
    <div class="container-fluid bg-black pt-6 pb-8">
      <div>
      <h2 class="heading-pages ">What is Design?</h2>
      </div>
      <img src="@/assets/images/laptop.png" alt="" class=" ui-img" />
    </div>
   <!-- <div class="container-boxed bg-black ">
      <div class="underline ... color-orange">
        <h2 class="heading-page-md">Design Blogs</h2>
      </div>
      <div class="grid grid-cols-3   divider-sec">
        <div class="blog-ui">
          <img src="@/assets/images/blog.png" alt="" class="ui-blog1" />
          <p class=" md-para11 ">Why you should execute your</p>
          <p class=" md-para12 ">App idea if you trust it</p>
          <p class=" md-para13 ">View More</p>
        </div>
        <div class="blog-ui">
          <img src="@/assets/images/blog.png" alt="" class="ui-blog2" />
          <p class=" md-para11 ">Why you should execute your</p>
          <p class=" md-para12 ">App idea if you trust it</p>
          <p class=" md-para13 ">View More</p>
        </div>
        <div class="blog-ui">
          <img src="@/assets/images/blog.png" alt="" class="ui-blog3" />
          <p class=" md-para11 ">Why you should execute your</p>
          <p class=" md-para12 ">App idea if you trust it</p>
          <p class=" md-para13 ">View More</p>
        </div>
      </div>
    </div> -->
    <!-- <div class="">
      <div>
        <h2 class="heading-pages ">Design Blogs</h2>
      </div>
      <div class="flex padding-ms">
        <div class="w-1/3 services-border">
          <div class="bg-black">
            <div
              class="max-w-sm rounded overflow-hidden shadow-lg bg-black services-flex group border-orange hover:bg-transparent hover:border-orange "
            >
              <img src="@/assets/images/blog.png" alt="" class="ui-blog1 footer-3" />
            <p class=" md-para11 ">Why you should execute your</p>
            <p class=" md-para12 ">App idea if you trust it</p>
            <p class=" md-para13 ">View More</p>
            </div>
          </div>
        </div>
        <div class="w-1/3 services-border">
          <div class="bg-black">
            <div
              class="max-w-sm rounded overflow-hidden shadow-lg bg-black services-flex"
            >
              <img src="@/assets/images/blog.png" alt="" class="ui-blog1 footer-3" />
            <p class=" md-para11 ">Why you should execute your</p>
            <p class=" md-para12 ">App idea if you trust it</p>
            <p class=" md-para13 ">View More</p>
            </div>
          </div>
        </div>
        <div class="w-1/3 services-border">
          <div class="bg-black">
            <div
              class="max-w-sm rounded overflow-hidden shadow-lg bg-black services-flex"
            >
              <img src="@/assets/images/blog.png" alt="" class="ui-blog1 footer-3" />
            <p class=" md-para11 ">Why you should execute your</p>
            <p class=" md-para12 ">App idea if you trust it</p>
            <p class=" md-para13 ">View More</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="container-fluid  marketing-footer">
      <h2 class="footer-marketing">Lets team up and have conversation!</h2>
      <h1 class="footer-hire">Hire Us!</h1>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from './partials/Footer.vue'
export default {
  components: {
    Footer
  },
  methods: {
    openNav () {
      document.getElementById('mySidenav').style.width = '250px'
    },
    closeNav () {
      document.getElementById('mySidenav').style.width = '0'
    }
  }
}
</script>
<style scoped>
/*
dropdown  navbar code started
*/
@font-face {
font-family: "FontAwesome";
font-weight: normal;
font-style : normal;
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0") format("embedded-opentype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0") format("woff2"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0") format("woff"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0") format("truetype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular") format("svg");
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown > input[type="checkbox"] {
  position: absolute;
  left: -100vw;
}

.dropdown > label,
.dropdown > a[role="button"] {
  display: inline-block;
  /* padding: 6px 15px; */
  color: #333;
  line-height: 1.5em;
  text-decoration: none;
  /* border: 1px solid #8c8c8c; */
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.dropdown > label:hover,
.dropdown > a[role="button"]:hover,
.dropdown > a[role="button"]:focus {
  border-color: #333;
}

.dropdown > label:after,
.dropdown > a[role="button"]:after {
  content: "\f0d7";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: 6px;
}

.dropdown > ul {
  position: absolute;
  z-index: 999;
  display: block;
  left: -100vw;
  top: calc(1.5em + 14px);
  /* border: 1px solid #8c8c8c; */
  background: rgb(29, 29, 29);
  padding: 6px 15px;
  margin: 0;
  list-style: none;
  width: 220px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  -moz-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  box-shadow: 0 3px 8px rgba(0,0,0,.15);
}

.dropdown > ul a {
  display: block;
  padding: 6px 15px;
  text-decoration: none;
  color: rgb(95, 94, 94);
}

.dropdown > ul a:hover,
.dropdown > ul a:focus {
  background: rgb(29, 29, 29);
  color: #dd6605;
}

.dropdown > input[type="checkbox"]:checked ~ ul,
.dropdown > ul:target {
  left: 0;
}

.dropdown > [type="checkbox"]:checked + label:after,
.dropdown > ul:target ~ a:after {
  content: "\f0d8";
}

.dropdown a.close {
  display: none;
}

.dropdown > ul:target ~ a.close {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-indent: -100vw;
  z-index: 1000;
}
/*
 Demo purposes only
*/
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  padding: 30px;
  color: #333;
}

.dropdown + h2 {
  margin-top: 60px;
}
/*dropdown  navbar code finished
*/
</style>
