<template>
  <div class="bg-black">
      <div class="flex bg-gray-200">
      <div class="flex-1 bg-black">
        <router-link to="/"><img src="@/assets/images/logo.png" alt="" class="logo" /></router-link>
      </div>
      <div class="underline ... ">
        <h1 class="pages-heading3 text-white">Contact Us</h1>
      </div>
      <div class="side-bar">
        <div id="mySidenav" class="sidenav">
        <a href="javascript:void(0)" class="closebtn" v-on:click="closeNav()"
        >&times;</a>
          <a><router-link to="/">HOME</router-link></a>
          <a><router-link to="/about-us">ABOUT US</router-link></a>
          <a>
<div class="dropdown">
<input type="checkbox" id="my-dropdown">
<label for="my-dropdown" data-toggle="dropdown"><router-link to="/services">SERVICES</router-link></label>
<ul>
<li><a><router-link to="/marketing-solutions">MARKETING SOLUTIONS</router-link></a></li>
<li><a><router-link to="/web-development">WEB DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/mobile-development">APP DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/uiux">UIUX DESIGN</router-link></a></li>
</ul>
</div>
</a>
          <a><router-link to="/portfolio">PORTFOLIO</router-link></a>
          <a><router-link to="/contact">CONTACT US</router-link></a>
        </div>
        <img src="@/assets/images/navbar.svg" alt="" v-on:click="openNav()" class="navbar" />
      </div>
    </div>
    <div class="container-fluid bg-black ">
       <div>
      <h2 class="heading-pages ">Contact Us</h2>
       </div>
      <h2 class="contact-des topm">Lets team up and have conversation!</h2>
    </div>
    <section>
      <div class="contactForm">
        <form ref="form" @submit.prevent="sendEmail">
          <div class="inputBox">
            <input type="text" name="name" placeholder="Name*"  required="required">
          </div>
          <div class="inputBox">
            <input type="text" name="email" placeholder="Email*"  required="required">
          </div>
          <div class="inputBox">
            <input type="text" name="contact_no" PLaceholder="Contact No." required="required">
          </div>
          <div class="inputBox">
            <select name="services">
               <option>Services*</option>
               <option>Web Development</option>
               <option>App Development</option>
               <option>UX & UI Designing</option>
               <option>Graphic Designing</option>
            </select>
          </div>
           <div class="inputBox">
            <input type="text" name="message" placeholder="Message*" required="required">
          </div>
           <div class="inputBox">
            <input type="submit" name="" value="Send">
          </div>
        </form>
      </div>
    </section>
    <div class="{styles.main_container}">
        <div class="flex bg-black topm">
          <div class="flex-1 text-gray-700 text-center bg-black px-4 py-2 m-2 bbc">
            <img src="@/assets/images/location.png" class="contact-icons"/>
             <h1 class=" contact-box-text1">
              114 Ayers ct, Teaneck,NJ - 07307<br><br>
              Friends Enclave, Koregaon Park Annex,Pune<br>Pin Code - 411036
            </h1>
          </div>
          <div class="flex-1 text-gray-700 text-center bg-black px-4 py-2 m-2 bbc">
            <img src="@/assets/images/call.png" class="contact-icons" />
            <h1 class="contact-box-text1">+1 (347) 651-5293, +91 9096536999</h1>
          </div>
          <div class="flex-1 text-gray-700 text-center bg-black px-4 py-2 m-2 bbc">
            <img src="@/assets/images/mail.png" class="contact-icons" />
            <h1 class="contact-box-text1">contact@rdiobutton.com</h1>
          </div>
        </div>
      </div><br><br>
      <!-- <div class="{styles.main_container}">
        <div >
          <h2 class="heading-pagesw ">Connect with us</h2>
        </div>
        <div class="flex bg-black topm"  style="margin-left:-20px;">
          <div class="flex-1 text-gray-700 text-center bg-black px-4 py-2 m-2">
          </div>
          <div class="flex-1 text-gray-700 text-center bg-black px-4 py-2 m-2">
            <a href="#" class="fa fa-facebook"><img src="@/assets/images/fb.png" class="contact-i"/></a>
           <a href="#" class="fa fa-facebook"> <img src="@/assets/images/insta.png" class="contact-i"/></a>
            <a href="#" class="fa fa-facebook"><img src="@/assets/images/in.png" class="contact-i"/></a>
          </div>
          <div class="flex-1 text-gray-700 text-center bg-black px-4 py-2 m-2">
          </div>
        </div>
      </div><br><br> -->
       <!-- <div class="{styles.main_container}">
        <div >
          <h2 class="heading-pagesw ">Connect with us</h2>
        </div> -->
          <!-- <div class="flex-1 text-gray-700 text-center bg-black px-4 py-2 m-2">
             <img src="@/assets/images/fb.png" class="contact-icons"/>
          </div>
          <div class="flex-1 text-gray-700 text-center bg-black px-4 py-2 m-2">
            <img src="@/assets/images/insta.png" class="contact-icons" />
          </div>
          <div class="flex-1 text-gray-700 text-center bg-black px-4 py-2 m-2">
             <img src="@/assets/images/in.png" class="contact-icons" />
          </div> -->
          <!-- <div class="flex-1 text-gray-700 text-center bg-black px-4 py-2 m-2" style="margin-left:-20px;">
          <a href="#" class="fa fa-facebook"><img src="@/assets/images/fb.png" class="contact-i"/></a>
          <a href="#" class="fa fa-twitter"><img src="@/assets/images/insta.png" class="contact-i"/></a>
          <a href="#" class="fa fa-google"><img src="@/assets/images/in.png" class="contact-i"/></a>
          </div>
      </div><br><br><br><br> -->
    <!-- <div class="mm1 container-fluid bg-black">
      <div class="grid grid-cols-3 pt-4 pb-8 ">
        <div>
          <div class="box-border border-2 ... contact-box1 icon-boxes">
            <img src="@/assets/images/location.png" alt="" class="contact-icons" />
            <h1 class=" contact-box-text1">
              114 Ayers ct, Teaneck,NJ - 07307<br><br>
              Friends Enclave, Koregaon Park Annex,Pune<br>Pin Code - 411036
            </h1>
          </div>
        </div>
        <div>
          <div class="box-border border-2 contact-box2 icon-boxes">
            <img src="@/assets/images/call.png" alt="" class="contact-icons" />
            <h1 class="contact-box-text">+1 (347) 651-5293
              <br><br>+91 9096536999</h1>
          </div>
        </div>
        <div>
          <div class="box-border border-2 ... contact-box3 icon-boxes">
            <img src="@/assets/images/mail.png" alt="" class="contact-icons" />
            <h1 class="contact-box-text3">contact@rdiobutton.com</h1>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="container-fluid bg-black ">
       <div>
      <h2 class="heading-pages ">Connect with us</h2>
      </div>
      <div class="grid grid-cols-3 pt-4 pb-8 icon-box ">
        <div>
          <a href="https://www.facebook.com/rdiobutton/"><img src="@/assets/images/fb.png" alt="" class="contact-icon icon1" /></a>
        </div>
        <div>
         <a href="https://instagram.com/rdiobutton_official?utm_medium=copy_link"><img src="@/assets/images/insta.png" alt="" class="contact-icon icon2" /></a>
        </div>
        <div>
          <img src="@/assets/images/in.png" alt="" class="contact-icon icon3" />
        </div>
      </div>
    </div> -->
   <Footer></Footer>
  </div>
</template>

<script>
import Footer from './partials/Footer.vue'
import emailjs from 'emailjs-com'
export default {
  components: {
    Footer
  },
  methods: {
    openNav () {
      document.getElementById('mySidenav').style.width = '250px'
    },
    closeNav () {
      document.getElementById('mySidenav').style.width = '0'
    },
    sendEmail () {
      emailjs.sendForm('service_qelpcx4', 'template_rdsoxwm', this.$refs.form, 'user_2mydx2XI0sCHC3LcNG2CC')
        .then((result) => {
          console.log('SUCCESS!', result.text)
        }, (error) => {
          console.log('FAILED...', error.text)
        })
    }
  }
}
</script>
<style scoped>
/*
dropdown  navbar code started
*/
@font-face {
font-family: "FontAwesome";
font-weight: normal;
font-style : normal;
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0") format("embedded-opentype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0") format("woff2"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0") format("woff"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0") format("truetype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular") format("svg");
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown > input[type="checkbox"] {
  position: absolute;
  left: -100vw;
}

.dropdown > label,
.dropdown > a[role="button"] {
  display: inline-block;
  /* padding: 6px 15px; */
  color: #333;
  line-height: 1.5em;
  text-decoration: none;
  /* border: 1px solid #8c8c8c; */
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.dropdown > label:hover,
.dropdown > a[role="button"]:hover,
.dropdown > a[role="button"]:focus {
  border-color: #333;
}

.dropdown > label:after,
.dropdown > a[role="button"]:after {
  content: "\f0d7";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: 6px;
}

.dropdown > ul {
  position: absolute;
  z-index: 999;
  display: block;
  left: -100vw;
  top: calc(1.5em + 14px);
  /* border: 1px solid #8c8c8c; */
  background: rgb(29, 29, 29);
  padding: 6px 15px;
  margin: 0;
  list-style: none;
  width: 220px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  -moz-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  box-shadow: 0 3px 8px rgba(0,0,0,.15);
}

.dropdown > ul a {
  display: block;
  padding: 6px 15px;
  text-decoration: none;
  color: rgb(95, 94, 94);
}

.dropdown > ul a:hover,
.dropdown > ul a:focus {
  background: rgb(29, 29, 29);
  color: #dd6605;
}

.dropdown > input[type="checkbox"]:checked ~ ul,
.dropdown > ul:target {
  left: 0;
}

.dropdown > [type="checkbox"]:checked + label:after,
.dropdown > ul:target ~ a:after {
  content: "\f0d8";
}

.dropdown a.close {
  display: none;
}

.dropdown > ul:target ~ a.close {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-indent: -100vw;
  z-index: 1000;
}
/*
 Demo purposes only
*/
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  padding: 30px;
  color: #333;
}

.dropdown + h2 {
  margin-top: 60px;
}
/*dropdown  navbar code finished
*/

@media screen and (max-width: 700px) {

    body{
        overflow-x: hidden;
    }
}
</style>
