<template>
  <div class="bg-black">
      <div class="flex bg-gray-200">
      <div class="flex-1 bg-black">
        <router-link to="/"><img src="@/assets/images/logo.png" alt="" class="logo" /></router-link>
      </div>
      <div class="underline ... ">
        <h1 class="pages-heading3 text-white">Case Study</h1>
      </div>
      <div class="side-bar">
        <div id="mySidenav" class="sidenav">
        <a href="javascript:void(0)" class="closebtn" v-on:click="closeNav()"
        >&times;</a>
          <a><router-link to="/">HOME</router-link></a>
          <a><router-link to="/about-us">ABOUT US</router-link></a>
          <a>
<div class="dropdown">
<input type="checkbox" id="my-dropdown">
<label for="my-dropdown" data-toggle="dropdown"><router-link to="/services">SERVICES</router-link></label>
<ul>
<li><a><router-link to="/marketing-solutions">MARKETING SOLUTIONS</router-link></a></li>
<li><a><router-link to="/web-development">WEB DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/mobile-development">APP DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/uiux">UIUX DESIGN</router-link></a></li>
</ul>
</div>
</a>
          <a><router-link to="/portfolio">PORTFOLIO</router-link></a>
          <a><router-link to="/contact">CONTACT US</router-link></a>
        </div>
        <img src="@/assets/images/navbar.svg" alt="" v-on:click="openNav()" class="navbar" />
      </div>
    </div>
    <div class="container-fluid bg-black ">
      <div class="grid grid-cols-2">
        <div>
          <div class=" ">
            <h2 class="text-white about-head1">American Capital Mortgage Inc. (ACMI)</h2>
          </div>
          <p class="text-white about-para">
              How we helped ACMI to reach out to its tech savvy customers.<br><br>
             <strong>Summary-</strong> A25-year-old organization, had already positioned itself as a renamed financial
             service institution in the US. ACMI was still practicing an old traditional marketing way
              of reaching out to permanent investors to sell their mortgage loans. As ACMI got matured
               and loomed on the horizon of the digital era, they thought to increase their visibility on internet.
            <br><br><strong>Challenge-</strong>At Rdiobutton, we make sure that we initiate the process with all the necessary
             information i.e. goal and problems of our customers. Here, the challenge was to mark a presence in the online world and appeal new customers.
             <br><br><strong>Our roles-</strong><br>
            1.Website development<br>
            2.Digital marketing<br>
            3.Upgraded User Experience
            <br><br><strong>Solution-</strong><br>We createdan entire new experience through the following –<br>
            1.Made a simple and clean user interface.<br>
            2.Formed an alluring website with the help of crafted content and basic animation.<br>
            3.Formed a mortgage calculator too for the convenience of the mortgage broker.<br>
            4.Enteredinto social media campaigns
            <br><br><strong>Result-</strong> Our re-imagined experience helped the client to generate around 55 leads and as a result the client’s business revenue was increased by 27%.
          </p>
        </div>
        <div>
          <img src="@/assets/images/g1.png" alt="" class="marketing-img11" />
        </div>
      </div>
    </div>
   <Footer></Footer>
  </div>
</template>

<script>
import Footer from './partials/Footer.vue'
export default {
  components: {
    Footer
  },
  methods: {
    openNav () {
      document.getElementById('mySidenav').style.width = '250px'
    },
    closeNav () {
      document.getElementById('mySidenav').style.width = '0'
    }
  }
}
</script>
<style scoped>
/*
dropdown  navbar code started
*/
@font-face {
font-family: "FontAwesome";
font-weight: normal;
font-style : normal;
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0") format("embedded-opentype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0") format("woff2"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0") format("woff"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0") format("truetype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular") format("svg");
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown > input[type="checkbox"] {
  position: absolute;
  left: -100vw;
}

.dropdown > label,
.dropdown > a[role="button"] {
  display: inline-block;
  /* padding: 6px 15px; */
  color: #333;
  line-height: 1.5em;
  text-decoration: none;
  /* border: 1px solid #8c8c8c; */
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.dropdown > label:hover,
.dropdown > a[role="button"]:hover,
.dropdown > a[role="button"]:focus {
  border-color: #333;
}

.dropdown > label:after,
.dropdown > a[role="button"]:after {
  content: "\f0d7";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: 6px;
}

.dropdown > ul {
  position: absolute;
  z-index: 999;
  display: block;
  left: -100vw;
  top: calc(1.5em + 14px);
  /* border: 1px solid #8c8c8c; */
  background: rgb(29, 29, 29);
  padding: 6px 15px;
  margin: 0;
  list-style: none;
  width: 220px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  -moz-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  box-shadow: 0 3px 8px rgba(0,0,0,.15);
}

.dropdown > ul a {
  display: block;
  padding: 6px 15px;
  text-decoration: none;
  color: rgb(95, 94, 94);
}

.dropdown > ul a:hover,
.dropdown > ul a:focus {
  background: rgb(29, 29, 29);
  color: #dd6605;
}

.dropdown > input[type="checkbox"]:checked ~ ul,
.dropdown > ul:target {
  left: 0;
}

.dropdown > [type="checkbox"]:checked + label:after,
.dropdown > ul:target ~ a:after {
  content: "\f0d8";
}

.dropdown a.close {
  display: none;
}

.dropdown > ul:target ~ a.close {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-indent: -100vw;
  z-index: 1000;
}
/*
 Demo purposes only
*/
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  padding: 30px;
  color: #333;
}

.dropdown + h2 {
  margin-top: 60px;
}
/*dropdown  navbar code finished
*/
</style>
