import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/components/Home.vue'
import About from '@/components/About.vue'
import MarketingSolutions from '@/components/MarketingSolutions.vue'
import MobileDevelopment from '@/components/MobileDevelopment.vue'
import Nav from '@/components/Nav.vue'
import Services from '@/components/Services.vue'
import CaseStudy1 from '@/components/casestudy1.vue'
import CaseStudy2 from '@/components/casestudy2.vue'
import Portfolio from '@/components/portfolio.vue'
import Uiux from '@/components/Uiux.vue'
import WebDevelopment from '@/components/WebDevelopment.vue'
import Blog from '@/components/Blog.vue'
import Contact from '@/components/Contact.vue'
import Slider from '@/components/slider.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about-us',
    name: 'About',
    component: About
  },
  {
    path: '/marketing-solutions',
    name: 'Marketing Solutions',
    component: MarketingSolutions
  },
  {
    path: '/mobile-development',
    name: 'Mobile Development',
    component: MobileDevelopment
  },
  {
    path: '/nav',
    name: 'Nav',
    component: Nav
  },
  {
    path: '/slider',
    name: 'Slider',
    component: Slider
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/uiux',
    name: 'Uiux',
    component: Uiux
  },
  {
    path: '/web-development',
    name: 'Web Development',
    component: WebDevelopment
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/casestudy1',
    name: 'CaseStudy1',
    component: CaseStudy1
  },
  {
    path: '/casestudy2',
    name: 'CaseStudy2',
    component: CaseStudy2
  },

  {
    path: '/portfolio',
    name: 'Portfolio',
    component: Portfolio
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
