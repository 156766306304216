<template>
  <div class="bg-black">
     <div class="flex bg-gray-200">
      <div class="flex-1 bg-black">
        <router-link to="/"><img src="@/assets/images/logo.png" alt="" class="logo" /></router-link>
      </div>
      <div class="underline ... ">
        <h1 class="pages-heading2 text-white">About Us</h1>
      </div>
      <div class="side-bar">
        <div id="mySidenav" class="sidenav">
        <a href="javascript:void(0)" class="closebtn" v-on:click="closeNav()"
        >&times;</a>
          <a><router-link to="/">HOME</router-link></a>
          <a><router-link to="/about-us">ABOUT US</router-link></a>
         <a>
<div class="dropdown">
<input type="checkbox" id="my-dropdown">
<label for="my-dropdown" data-toggle="dropdown"><router-link to="/services">SERVICES</router-link></label>
<ul>
<li><a><router-link to="/marketing-solutions">MARKETING SOLUTIONS</router-link></a></li>
<li><a><router-link to="/web-development">WEB DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/mobile-development">APP DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/uiux">UIUX DESIGN</router-link></a></li>
</ul>
</div>
</a>
          <a><router-link to="/portfolio">PORTFOLIO</router-link></a>
          <a><router-link to="/contact">CONTACT US</router-link></a>
        </div>
        <img src="@/assets/images/navbar.svg" alt="" v-on:click="openNav()" class="navbar" />
      </div>
    </div>
    <div class="container-fluid bg-black ">
      <div class="grid grid-cols-2 ">
        <div>
          <div>
            <h2 class="text-white about-head1">Solving Your Business And Growth Pain With Our Technology And Digital Solutions...
            </h2>
          </div>
          <input type="checkbox" class="read-more-state" id="post-1" />
            <p class="read-more-wrap text-white about-para">
              Rdiobutton is a growth-focused digital agency. We accelerate the business potential by unlocking
              today’s digital developments. And make sure we cater to the best and long-lasting digital experience
              for our customers.
              <!-- <button for="post-1" class="read-more-trigger button-services bg-orange-500 hover:bg-transparent text-white font-bold py-2 px-2 border border-orange -700 rounded">Read More..</button> -->
                  <!-- <p id="panel" class="text-white about-para"> -->
                    <span class="read-more-target">
                  Since the inception of Rdiobutton our mission is the same which is to create a significant impact to
                  the world with the technology. More than results we focus on the process as we believe if your work
                  process is disciplined and sturdy then results will follow.
                  It’s all about relieving the pain of customers with our technology and digital solutions. We are aware
                  of the fact that great customer experience can be achieved by providing value and not with building
                  features and that is what we aim to deliver to customers.</span> </p>
                   <label for="post-1" class="read-more-trigger  button-services-a bg-orange-500 hover:bg-transparent text-white font-bold py-2 px-4 border border-orange -700 rounded"></label>
         </div>
        <div>
          <img src="@/assets/images/team2.png" alt="" class="about-img1" />
        </div>
      </div>
    </div>>
    <div class="container-fluid bg-black ">
      <div>
        <div class="underline ... color-orange">
          <!-- <h2 class="heading-pages work-head ">How We Work?</h2> -->
        </div>
      </div>
    </div>
    <div class="container-fluid bg-black ">
      <div>
        <h2 class="heading-pages work-head ">Meet Our Team</h2>
      </div>
      <div class="grid grid-cols-2 " style="padding-bottom:50px">
        <div>
          <div class="grid grid-cols-2" style="margin-top:15px;">
            <div>
              <!-- <img src="@/assets/images/1.png" alt="" class="img-ceo" />
              <h2 class="des-ceo">CEO</h2> -->
              <div class="thumbnail">
                <div>CEO</div>
                <img src="@/assets/images/1.png" alt="" class="img-team2" />
              </div>
            </div>
            <div class="ba">
              <!-- <img src="@/assets/images/9.png" alt="" class="img-ceo" />
              <h2 class="des-ceo">COO</h2> -->
              <div class="thumbnail">
                <div>COO</div>
                <img src="@/assets/images/9.png" alt="" class="img-team2" />
              </div>
            </div>
          </div>
        </div>
        <p class="text-white team-para" style="margin-top:10px;" >
          Our team of diversified experts brings together extraordinary brand experiences.We confront the
          tech-driven world with dexterity, honour the nuances that make each one of us unique in our own
          way, and also at a continuous pace we strive for perfection and improvement
          Above all, we are PEOPLE-FOCUSED and a team of thinkers and innovators who are lucky enough to
          re-imagine the digital products for our customers.
        </p>
      </div>
      <div class="grid grid-cols-4 " style="margin-bottom:30px;">
        <div>
          <!-- <img src="@/assets/images/13.png" alt="" class="img-team1" />
          <h2 class="teams-des ">Mobile App Developer</h2> -->
              <div class="thumbnail">
                <div>Mobile App Developer</div>
                <img src="@/assets/images/13.png" alt="" class="img-team2" />
              </div>
        </div>
        <div class="ba">
          <!-- <img src="@/assets/images/3.png" alt="" class="img-team2" />
          <h2 class="team-des2">Senior UI/UX Designer</h2> -->
              <div class="thumbnail">
                <div>Graphic Designer</div>
                <img src="@/assets/images/3.png" alt="" class="img-team2" />
              </div>
        </div>
       <div class="ba">
          <!-- <img src="@/assets/images/12.jpg" alt="" class="img-team3" />
          <h2 class="team-des3">Full Stack Developer</h2> -->
              <div class="thumbnail">
                <div>Full Stack Developer</div>
                <img src="@/assets/images/12.jpg" alt="" class="img-team2" />
              </div>
        </div>
         <div class="ba">
          <!-- <img src="@/assets/images/bw1.png" alt="" class="img-team4" />
          <h2 class="team-des4 ">Junior Web Developer</h2> -->
              <div class="thumbnail">
                <div>Senior UI/UX Designer</div>
                <img src="@/assets/images/bw1.png" alt="" class="img-team2" />
              </div>
        </div>
      </div>
      <div class="grid grid-cols-4 " style="margin-bottom:30px;margin-top:50px;">
        <div>
              <div class="thumbnail">
                <div>Senior Web Developer</div>
                <img src="@/assets/images/neha.jpg" alt="" class="img-team2" />
              </div>
        </div>
        <div>
        </div>
        <div>
          <!-- <img src="@/assets/images/12.jpg" alt="" class="img-team3" />
          <h2 class="team-des3">Full Stack Developer</h2> -->
            <!-- <figure>
              <div class="thumbnail">
                <div>Full Stack Developer</div>
                <img src="@/assets/images/12.jpg" alt="" class="img-team3" />
              </div>
          </figure> -->
        </div>

        <!-- <div>
          <img src="@/assets/images/IMG_6200.jpg" alt="" class="img-team4" />
          <h2 class="team-des4 ">Junior UI/UX Designer</h2>
        </div> -->
      </div>
    </div>
     <Footer></Footer>
  </div>
</template>

<script>
import Footer from './partials/Footer.vue'
export default {
  components: {
    Footer
  },
  methods: {
    openNav () {
      document.getElementById('mySidenav').style.width = '250px'
    },
    closeNav () {
      document.getElementById('mySidenav').style.width = '0'
    }
  }
}
</script>
<style scoped>
/*
dropdown  navbar code started
*/
@font-face {
font-family: "FontAwesome";
font-weight: normal;
font-style : normal;
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0") format("embedded-opentype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0") format("woff2"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0") format("woff"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0") format("truetype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular") format("svg");
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown > input[type="checkbox"] {
  position: absolute;
  left: -100vw;
}

.dropdown > label,
.dropdown > a[role="button"] {
  display: inline-block;
  /* padding: 6px 15px; */
  color: #333;
  line-height: 1.5em;
  text-decoration: none;
  /* border: 1px solid #8c8c8c; */
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.dropdown > label:hover,
.dropdown > a[role="button"]:hover,
.dropdown > a[role="button"]:focus {
  border-color: #333;
}

.dropdown > label:after,
.dropdown > a[role="button"]:after {
  content: "\f0d7";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: 6px;
}

.dropdown > ul {
  position: absolute;
  z-index: 999;
  display: block;
  left: -100vw;
  top: calc(1.5em + 14px);
  /* border: 1px solid #8c8c8c; */
  background: rgb(29, 29, 29);
  padding: 6px 15px;
  margin: 0;
  list-style: none;
  width: 220px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  -moz-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  box-shadow: 0 3px 8px rgba(0,0,0,.15);
}

.dropdown > ul a {
  display: block;
  padding: 6px 15px;
  text-decoration: none;
  color: rgb(95, 94, 94);
}

.dropdown > ul a:hover,
.dropdown > ul a:focus {
  background: rgb(29, 29, 29);
  color: #dd6605;
}

.dropdown > input[type="checkbox"]:checked ~ ul,
.dropdown > ul:target {
  left: 0;
}

.dropdown > [type="checkbox"]:checked + label:after,
.dropdown > ul:target ~ a:after {
  content: "\f0d8";
}

.dropdown a.close {
  display: none;
}

.dropdown > ul:target ~ a.close {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-indent: -100vw;
  z-index: 1000;
}
/*
 Demo purposes only
*/
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  padding: 30px;
  color: #333;
}

.dropdown + h2 {
  margin-top: 60px;
}
/*dropdown  navbar code finished
*/
.read-more-state {
  display: none;
}

.read-more-target {
  opacity: 0;
  max-height: 0;
  font-size: 0;
  transition: .25s ease;
}

.read-more-state:checked ~ .read-more-wrap .read-more-target {
  opacity: 1;
  font-size: inherit;
  max-height: 999em;
}

.read-more-state ~ .read-more-trigger:before {
  content: 'Read more';
}

.read-more-state:checked ~ .read-more-trigger:before {
  content: 'Read less';
}

 .read-more-trigger {
  cursor: pointer;
  display: inline-block;
  padding: 0 .5em;
  font-size: .9em;
  line-height: 3;
    width: 105px;
    margin-left: 12%;
    text-align: center;
}

@media screen and (max-width: 700px) {
.read-more-trigger {
  cursor: pointer;
  display: inline-block;
  margin-top: 10px;
  padding: 0 .5em;
  font-size: .9em;
  line-height: 2;
  margin-left:9%;
  width:90px;
}
}
</style>
