<template>
  <div class="bg-black">
     <div class="flex bg-gray-200">
      <div class="flex-1 bg-black">
        <router-link to="/"><img src="@/assets/images/logo.png" alt="" class="logo" /></router-link>
      </div>
      <div class="underline ... ">
        <h1 class="pages-heading-c text-white">Mobile Development</h1>
      </div>
      <div class="side-bar">
        <div id="mySidenav" class="sidenav">
        <a href="javascript:void(0)" class="closebtn" v-on:click="closeNav()"
        >&times;</a>
          <a><router-link to="/">HOME</router-link></a>
          <a><router-link to="/about-us">ABOUT US</router-link></a>
          <a>
<div class="dropdown">
<input type="checkbox" id="my-dropdown">
<label for="my-dropdown" data-toggle="dropdown"><router-link to="/services">SERVICES</router-link></label>
<ul>
<li><a><router-link to="/marketing-solutions">MARKETING SOLUTIONS</router-link></a></li>
<li><a><router-link to="/web-development">WEB DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/mobile-development">APP DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/uiux">UIUX DESIGN</router-link></a></li>
</ul>
</div>
</a>
          <a><router-link to="/portfolio">PORTFOLIO</router-link></a>
          <a><router-link to="/contact">CONTACT US</router-link></a>
        </div>
        <img src="@/assets/images/navbar.svg" alt="" v-on:click="openNav()" class="navbar" />
      </div>
    </div>
    <div class="container-fluid bg-black ">
      <div class="grid grid-cols-2 ">
        <div class="bbb">
          <div>
            <h2 class="text-white about-head1">Mobile App Development</h2>
          </div>
          <input type="checkbox" class="read-more-state" id="post-1" />
            <p class="read-more-wrap text-white about-para">
            We craft secure, scalable, efficient and stable mobile applications with the splash of creativity. The
            goal is always to deliver more than you expected.  Investing in mobile application is more than just
            building a great mobile app, the real challenge starts after the launch of the app and especially when
            you are building business around mobile application.<br>
           <span class="read-more-target">
            Therefore, we believe that you don’t just need
            a great product but a great team who will help you grow your mobile apps taking a right tech
            decision along the way.
            We believe that the coding and design is easy but to build the right product is the most difficult part
            and that is why our focus in not only on building great mobile apps but to grow your mobile
            application business through our extensive research and result oriented strategies.</span></p>
             <label for="post-1" class="read-more-trigger  button-services bg-orange-500 hover:bg-transparent text-white font-bold py-2 px-2 border border-orange -700 rounded"></label>
        </div>
        <div>
          <img
            src="@/assets/images/girl-standing-2a_00000.gif"
            alt=""
            class="marketing-img2"
          />
        </div>
      </div>
    </div>
    <div class="container-boxed bg-black ">
       <div>
      <h2 class="heading-pages ">Technologies we use for</h2>
       </div>
      <div class="grid grid-cols-2 divide-x divide-grey  md-divider ">
        <div class=" divider-wd ">
          <div class=" pt-2 pb-2">Android</div>
          <p class="divider-para">
            Lorem ipsum dolor sit consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <div class="grid grid-cols-2 ">
            <div>
              <img src="@/assets/images/symbol3.svg" alt="" class="wd-svg1" />
            </div>
            <div>
              <img src="@/assets/images/symbol10.svg" alt="" class="wd-svg2" />
            </div>
          </div>
        </div>
        <div class=" divider-wd">
          <div class=" pt-2 pb-2">iOS</div>
          <p class="divider-para">
            Lorem ipsum dolor sit consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <div class="grid grid-cols-2 ">
            <div>
              <img src="@/assets/images/symbol12.svg" alt="" class="md-svg3" />
            </div>
            <div>
              <img src="@/assets/images/symbol11.svg" alt="" class="md-svg4" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-boxed bg-black ">
      <div>
      <h2 class="heading-pages ">Why Rdiobutton for App Development?</h2>
      </div>
      <div class="box-content wd-box">
        <div class="grid grid-cols-2 ">
          <div>
            <img src="@/assets/images/A1.png" alt="" class="wd-num" />
          </div>
          <p class="md-smm" >
            We know the importance of design when it comes to the product development and that is why we
            are laser focused on creating great user interfaces for your mobile application. We believe a great
           design is not just about how thing looks but how it solves your problems and provide a great
           experience. Our team of designer work with the development team to provide stunning mobile applications which are sturdy and high performing.
           </p>
        </div>
      </div>
      <div class="box-content wd-box">
        <div class="grid grid-cols-2 ">
          <div>
            <img src="@/assets/images/A2.png" alt="" class="wd-num" />
          </div>
          <p class="md-smm" >
            We don’t compromise over a quality of the code. It is our strong belief that product has to be good
           inside out and only then we will be truly satisfied with the process of creation. We implement clean
           code practice and the code we write is solid and maintainable.
          </p>
        </div>
      </div>
      <div class="box-content wd-box">
        <div class="grid grid-cols-2 ">
          <div>
            <img src="@/assets/images/A3.png" alt="" class="wd-num" />
          </div>
          <p class="md-smm" >
            We understand the mobile applications we are building are going to influence your business, finance
            and lifestyle. Our goal is not to make a mobile application but to help you grow with our services and
            that is why we get involved in your product vision and help you grow even after the launch. Think of
           us as your team members, helping you take a right decision in your mobile app growth campaigns.
          </p>
        </div>
      </div>
      <div class="box-content wd-box">
        <div class="grid grid-cols-2 ">
          <div>
            <img src="@/assets/images/A4.png" alt="" class="wd-num" />
          </div>
          <p class="md-smm">
            Every app we create is tested to perfection in all the scenarios to make sure your customer gets a
            100% ready mobile application.
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="container-boxed bg-black ">
      <div class="underline ... color-orange">
        <h2 class="heading-page-md">App Development Blogs</h2>
      </div>
      <div class="grid grid-cols-3   divider-sec">
        <div class="blog-ui">
          <img src="@/assets/images/blog.png" alt="" class="ui-blog1" />
          <p class=" md-para11 ">Why you should execute your</p>
          <p class=" md-para12 ">App idea if you trust it</p>
          <p class=" md-para13 ">View More</p>
        </div>
        <div class="blog-ui">
          <img src="@/assets/images/blog.png" alt="" class="ui-blog2" />
          <p class=" md-para11 ">Why you should execute your</p>
          <p class=" md-para12 ">App idea if you trust it</p>
          <p class=" md-para13 ">View More</p>
        </div>
        <div class="blog-ui">
          <img src="@/assets/images/blog.png" alt="" class="ui-blog3" />
          <p class=" md-para11 ">Why you should execute your</p>
          <p class=" md-para12 ">App idea if you trust it</p>
          <p class=" md-para13 ">View More</p>
        </div>
      </div>
    </div> -->
    <!-- <div class="">
      <div>
        <h2 class="heading-pages ">App Development Blogs</h2>
      </div>
      <div class="flex padding-ms">
        <div class="w-1/3 services-border">
          <div class="bg-black">
            <div
              class="max-w-sm rounded overflow-hidden shadow-lg bg-black services-flex group border-orange hover:bg-transparent hover:border-orange "
            >
              <img src="@/assets/images/blog.png" alt="" class="ui-blog1 footer-3" />
            <p class=" md-para11 ">Why you should execute your</p>
            <p class=" md-para12 ">App idea if you trust it</p>
            <p class=" md-para13 ">View More</p>
            </div>
          </div>
        </div>
        <div class="w-1/3 services-border">
          <div class="bg-black">
            <div
              class="max-w-sm rounded overflow-hidden shadow-lg bg-black services-flex"
            >
              <img src="@/assets/images/blog.png" alt="" class="ui-blog1 footer-3" />
            <p class=" md-para11 ">Why you should execute your</p>
            <p class=" md-para12 ">App idea if you trust it</p>
            <p class=" md-para13 ">View More</p>
            </div>
          </div>
        </div>
        <div class="w-1/3 services-border">
          <div class="bg-black">
            <div
              class="max-w-sm rounded overflow-hidden shadow-lg bg-black services-flex"
            >
              <img src="@/assets/images/blog.png" alt="" class="ui-blog1 footer-3" />
            <p class=" md-para11 ">Why you should execute your</p>
            <p class=" md-para12 ">App idea if you trust it</p>
            <p class=" md-para13 ">View More</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div style="text-align:right">
    <button
            class="button-img1 bg-black hover:bg-transparent text-white font-bold py-2 px-4 rounded"
         >
            <router-link to="/uiux"><img src="@/assets/images/Group 885.png" class="foo sn"  /></router-link>
    </button>
    </div>
    <div class="container-fluid  marketing-footer">
      <h2 class="footer-marketing">Lets team up and have conversation!</h2>
      <h1 class="footer-hire">Hire Us!</h1>
    </div>
   <Footer></Footer>
  </div>
</template>

<script>
import Footer from './partials/Footer.vue'
export default {
  components: {
    Footer
  },
  methods: {
    openNav () {
      document.getElementById('mySidenav').style.width = '250px'
    },
    closeNav () {
      document.getElementById('mySidenav').style.width = '0'
    }
  }
}
</script>
<style scoped>
/*
dropdown  navbar code started
*/
@font-face {
font-family: "FontAwesome";
font-weight: normal;
font-style : normal;
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0") format("embedded-opentype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0") format("woff2"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0") format("woff"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0") format("truetype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular") format("svg");
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown > input[type="checkbox"] {
  position: absolute;
  left: -100vw;
}

.dropdown > label,
.dropdown > a[role="button"] {
  display: inline-block;
  /* padding: 6px 15px; */
  color: #333;
  line-height: 1.5em;
  text-decoration: none;
  /* border: 1px solid #8c8c8c; */
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.dropdown > label:hover,
.dropdown > a[role="button"]:hover,
.dropdown > a[role="button"]:focus {
  border-color: #333;
}

.dropdown > label:after,
.dropdown > a[role="button"]:after {
  content: "\f0d7";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: 6px;
}

.dropdown > ul {
  position: absolute;
  z-index: 999;
  display: block;
  left: -100vw;
  top: calc(1.5em + 14px);
  /* border: 1px solid #8c8c8c; */
  background: rgb(29, 29, 29);
  padding: 6px 15px;
  margin: 0;
  list-style: none;
  width: 220px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  -moz-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  box-shadow: 0 3px 8px rgba(0,0,0,.15);
}

.dropdown > ul a {
  display: block;
  padding: 6px 15px;
  text-decoration: none;
  color: rgb(95, 94, 94);
}

.dropdown > ul a:hover,
.dropdown > ul a:focus {
  background: rgb(29, 29, 29);
  color: #dd6605;
}

.dropdown > input[type="checkbox"]:checked ~ ul,
.dropdown > ul:target {
  left: 0;
}

.dropdown > [type="checkbox"]:checked + label:after,
.dropdown > ul:target ~ a:after {
  content: "\f0d8";
}

.dropdown a.close {
  display: none;
}

.dropdown > ul:target ~ a.close {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-indent: -100vw;
  z-index: 1000;
}
/*
 Demo purposes only
*/
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  padding: 30px;
  color: #333;
}

.dropdown + h2 {
  margin-top: 60px;
}
/*dropdown  navbar code finished
*/
.read-more-state {
  display: none;
}

.read-more-target {
  opacity: 0;
  max-height: 0;
  font-size: 0;
  transition: .25s ease;
}

.read-more-state:checked ~ .read-more-wrap .read-more-target {
  opacity: 1;
  font-size: inherit;
  max-height: 999em;
}

.read-more-state ~ .read-more-trigger:before {
  content: 'Read more';
}

.read-more-state:checked ~ .read-more-trigger:before {
  content: 'Read less';
}

 .read-more-trigger {
  cursor: pointer;
  display: inline-block;
  margin-top:0 ;
  padding: 0 .8em;
  font-size: .9em;
  margin-left:13%;
  line-height: 3;
    width: 105px;
    text-align: center;
}

@media screen and (max-width: 700px) {
.read-more-trigger {
  cursor: pointer;
  display: inline-block;
  margin-top: 10px;
  padding: 0 .5em;
  font-size: .9em;
  line-height: 2;
  margin-left:9%;
}
}
</style>
