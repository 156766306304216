<template>
  <div class="bg-black">
     <div class="flex bg-gray-200">
      <div class="flex-1 bg-black">
        <router-link to="/"><img src="@/assets/images/logo.png" alt="" class="logo" /></router-link>
      </div>
      <div class="underline ... ">
        <h1 class="pages-heading-b text-white">Web Development</h1>
      </div>
      <div class="side-bar">
        <div id="mySidenav" class="sidenav">
        <a href="javascript:void(0)" class="closebtn" v-on:click="closeNav()"
        >&times;</a>
          <a><router-link to="/">HOME</router-link></a>
          <a><router-link to="/about-us">ABOUT US</router-link></a>
           <a>
<div class="dropdown">
<input type="checkbox" id="my-dropdown">
<label for="my-dropdown" data-toggle="dropdown"><router-link to="/services">SERVICES</router-link></label>
<ul>
<li><a><router-link to="/marketing-solutions">MARKETING SOLUTIONS</router-link></a></li>
<li><a><router-link to="/web-development">WEB DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/mobile-development">APP DEVELOPMENT</router-link></a></li>
<li><a><router-link to="/uiux">UIUX DESIGN</router-link></a></li>
</ul>
</div>
</a>
          <a><router-link to="/portfolio">PORTFOLIO</router-link></a>
          <a><router-link to="/contact">CONTACT US</router-link></a>
        </div>
        <img src="@/assets/images/navbar.svg" alt="" v-on:click="openNav()" class="navbar" />
      </div>
    </div>
    <div class="container-fluid bg-black ">
      <div class="grid grid-cols-2 ">
        <div>
          <div>
            <h2 class="text-white about-head1">Web Development</h2>
          </div>
          <p class="text-white about-para">
            <span style="color:#dd6605;">WEB SOLUTIONS WHICH TRANSFORM YOUR BUSINESS IN MOST DISRUPTIVE WAY</span><br>
            We create web solution which drives results. Our web development solutions contribute to
            everything from branding, lead generation to monthly revenue.  Whether you want to build web app
            for your business or set up a website which will generate a monthly revenue, we create web that
            creates a significant business impact
          </p>
        </div>
        <div>
          <img
            src="@/assets/images/boy-coding-2_00000.gif"
            alt=""
            class="marketing-img3 marketing-img118"
          />
        </div>
      </div>
    </div>
    <div class="container-fluid bg-black pt-6 pb-6 ">
      <div class="grid grid-cols-2 ">
        <div>
          <div class="grid grid-cols-4 ">
            <div>
              <img src="@/assets/images/symbol1.svg" alt="" class="wd-svg111" />
              <img src="@/assets/images/symbol2.svg" alt="" class="wd-svg222" />
            </div>
            <div>
              <img src="@/assets/images/symbol3.svg" alt="" class="wd-svg31" />
              <img src="@/assets/images/symbol4.svg" alt="" class="wd-svg41" />
              <img src="@/assets/images/symbol5.svg" alt="" class="wd-svg51" />
            </div>
            <div>
              <img src="@/assets/images/symbol8.svg" alt="" class="wd-svg6" />
              <img src="@/assets/images/symbol7up.svg" alt="" class="wd-svg7" />
              <img src="@/assets/images/symbol6.svg" alt="" class="wd-svg8" />
            </div>
            <div>
              <img src="@/assets/images/symbol9.svg" alt="" class="wd-svg9" />
            </div>
          </div>
        </div>
        <div>
           <div>
          <h2 class="wd-heading">Technologies we use</h2>
          </div>
          <p class=" wd-para">
          Building entire backend and frontend technologies from scratch. We use these technologies to
          deliver perfect web for your business
          </p>
        </div>
      </div>
    </div>
    <div class="container-boxed bg-black ">
       <div>
      <h2 class="heading-pages ">Why Rdiobutton for Web Development?</h2>
       </div>
      <div class="box-content wd-box">
        <div class="grid grid-cols-2 ">
          <div>
            <img src="@/assets/images/A1.png" alt="" class="wd-num" />
          </div>
          <p class="md-smm">
            Unlike 70% of the business websites, which are mostly designed by
            the development team, your website will be designed by a
            professional designer.
          </p>
        </div>
      </div>
      <div class="box-content wd-box">
        <div class="grid grid-cols-2 ">
          <div>
            <img src="@/assets/images/A2.png" alt="" class="wd-num" />
          </div>
          <p class="md-smm">
            Most business websites lack a UI-UX strategy, whereas our designers
            will create a logical UX and visually stunning UI for your website
            that will engage your customers and provide a better experience for
            website visitors.
          </p>
        </div>
      </div>
      <div class="box-content wd-box">
        <div class="grid grid-cols-2 ">
          <div>
            <img src="@/assets/images/A3.png" alt="" class="wd-num" />
          </div>
          <p class="md-smm">
            Website is not just an informative tool, but it has the potential to grow your monthly revenue and brand value. We create websites keeping your business objective in mind, and everything is designed and developed around your business objective.
          </p>
        </div>
      </div>
      <div class="box-content wd-box">
        <div class="grid grid-cols-2 ">
          <div>
            <img src="@/assets/images/A4.png" alt="" class="wd-num" />
          </div>
          <p class="md-smm">
            Your website will have all the technical elements which are
            necessary to rank better on search engines like Google. Your website
            will be more visible to your target customers on search engines.
          </p>
        </div>
      </div>
        <div class="box-content wd-box">
        <div class="grid grid-cols-2 ">
          <div>
            <img src="@/assets/images/A5.png" alt="" class="wd-num" />
          </div>
          <p class="md-smm">
            We follow clean code practices. Clean code is maintainable code and easily understandable for
            other developers. We are laser focused on the clean code which helps us improve codebases and
            prevent issues.
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="container-boxed bg-black ">
      <div class="container-boxed bg-black ">
        <div>
          <h2 class="heading-page-md">Web Development Blogs</h2>
        </div>
        <div class="grid grid-cols-3   divider-sec">
          <div class="blog-ui">
            <img src="@/assets/images/blog.png" alt="" class="ui-blog1 footer-3" />
            <p class=" md-para11 ">Why you should execute your</p>
            <p class=" md-para12 ">App idea if you trust it</p>
            <p class=" md-para13 ">View More</p>
          </div>
          <div class="blog-ui">
            <img src="@/assets/images/blog.png" alt="" class="ui-blog2 footer-4" />
            <p class=" md-para11 ">Why you should execute your</p>
            <p class=" md-para12 ">App idea if you trust it</p>
            <p class=" md-para13 ">View More</p>
          </div>
          <div class="blog-ui">
            <img src="@/assets/images/blog.png" alt="" class="ui-blog3 footer-5" />
            <p class=" md-para11 ">Why you should execute your</p>
            <p class=" md-para12 ">App idea if you trust it</p>
            <p class=" md-para13 ">View More</p>
          </div>
        </div>
      </div>
    </div> -->
        <!-- <div class="">
      <div>
        <h2 class="heading-pages ">Web Development Blogs</h2>
      </div>
      <div class="flex padding-ms">
        <div class="w-1/3 services-border">
          <div class="bg-black">
            <div
              class="max-w-sm rounded overflow-hidden shadow-lg bg-black services-flex group border-orange hover:bg-transparent hover:border-orange "
            >
              <img src="@/assets/images/blog.png" alt="" class="ui-blog1 footer-3" />
            <p class=" md-para11 ">Why you should execute your</p>
            <p class=" md-para12 ">App idea if you trust it</p>
            <p class=" md-para13 ">View More</p>
            </div>
          </div>
        </div>
        <div class="w-1/3 services-border">
          <div class="bg-black">
            <div
              class="max-w-sm rounded overflow-hidden shadow-lg bg-black services-flex"
            >
              <img src="@/assets/images/blog.png" alt="" class="ui-blog1 footer-3" />
            <p class=" md-para11 ">Why you should execute your</p>
            <p class=" md-para12 ">App idea if you trust it</p>
            <p class=" md-para13 ">View More</p>
            </div>
          </div>
        </div>
        <div class="w-1/3 services-border">
          <div class="bg-black">
            <div
              class="max-w-sm rounded overflow-hidden shadow-lg bg-black services-flex"
            >
              <img src="@/assets/images/blog.png" alt="" class="ui-blog1 footer-3" />
            <p class=" md-para11 ">Why you should execute your</p>
            <p class=" md-para12 ">App idea if you trust it</p>
            <p class=" md-para13 ">View More</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div style="text-align:right">
    <button
            class="button-img1 bg-black hover:bg-transparent text-white font-bold py-2 px-4 rounded"
         >
            <router-link to="/mobile-development"><img src="@/assets/images/Group 885.png" class="foo sn" /></router-link>
    </button>
    </div>
    <div class="container-fluid  marketing-footer">
      <h2 class="footer-marketing">Lets team up and have conversation!</h2>
      <h1 class="footer-hire">Hire Us!</h1>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from './partials/Footer.vue'
export default {
  components: {
    Footer
  },
  methods: {
    openNav () {
      document.getElementById('mySidenav').style.width = '250px'
    },
    closeNav () {
      document.getElementById('mySidenav').style.width = '0'
    }
  }
}
</script>
<style scoped>
/*
dropdown  navbar code started
*/
@font-face {
font-family: "FontAwesome";
font-weight: normal;
font-style : normal;
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
       src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0") format("embedded-opentype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0") format("woff2"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0") format("woff"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0") format("truetype"),
             url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular") format("svg");
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown > input[type="checkbox"] {
  position: absolute;
  left: -100vw;
}

.dropdown > label,
.dropdown > a[role="button"] {
  display: inline-block;
  /* padding: 6px 15px; */
  color: #333;
  line-height: 1.5em;
  text-decoration: none;
  /* border: 1px solid #8c8c8c; */
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.dropdown > label:hover,
.dropdown > a[role="button"]:hover,
.dropdown > a[role="button"]:focus {
  border-color: #333;
}

.dropdown > label:after,
.dropdown > a[role="button"]:after {
  content: "\f0d7";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: 6px;
}

.dropdown > ul {
  position: absolute;
  z-index: 999;
  display: block;
  left: -100vw;
  top: calc(1.5em + 14px);
  /* border: 1px solid #8c8c8c; */
  background: rgb(29, 29, 29);
  padding: 6px 15px;
  margin: 0;
  list-style: none;
  width: 220px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  -moz-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  box-shadow: 0 3px 8px rgba(0,0,0,.15);
}

.dropdown > ul a {
  display: block;
  padding: 6px 15px;
  text-decoration: none;
  color: rgb(95, 94, 94);
}

.dropdown > ul a:hover,
.dropdown > ul a:focus {
  background: rgb(29, 29, 29);
  color: #dd6605;
}

.dropdown > input[type="checkbox"]:checked ~ ul,
.dropdown > ul:target {
  left: 0;
}

.dropdown > [type="checkbox"]:checked + label:after,
.dropdown > ul:target ~ a:after {
  content: "\f0d8";
}

.dropdown a.close {
  display: none;
}

.dropdown > ul:target ~ a.close {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-indent: -100vw;
  z-index: 1000;
}
/*
 Demo purposes only
*/
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  padding: 30px;
  color: #333;
}

.dropdown + h2 {
  margin-top: 60px;
}
/*dropdown  navbar code finished
*/
</style>
